import React, { useState, useContext } from "react"
import { useSearchParams } from "react-router-dom"
const SoccerProvider = React.createContext()
export const SoccerContext = ({ children }) => {
    const [searchParams] = useSearchParams()
    const [selectedSection, setSelectedSection] = useState(searchParams.get('tab') || 'settings')
    const sidebarOpenQuery = searchParams.get('isSidebarOpen') === 'true' ? true : false
    const [isSidebarOpen, setIsSidebarOpen] = useState(sidebarOpenQuery)
    const initialState = { teams: [{ name: 'team1', state: 'team1' }, { name: 'team2', state: 'team2' }] , is_showing_results_background: true}
    const [data, setData] = useState(initialState)
    const [staticData, setStaticData] = useState(initialState)
    const [intervalClockId, setIntervalClockId] = useState(0)
    const [clockStatus, setClockStatus] = useState('reboot')
    const boardSettingsModalOpen = searchParams.get('isBoardSettingsModalOpen') === 'true' ? true : false
    const [isBoardSettingsOpen, setIsBoardSettingsOpen] = useState(boardSettingsModalOpen)
    // <lower | full>
    const [boardTab, setBoardTab] = useState(searchParams.get('settingsBoardTab') || 'lower')
    console.log(data)
    const values = {
        selectedSection,
        setSelectedSection,
        isSidebarOpen,
        setIsSidebarOpen,
        data,
        setData,
        intervalClockId,
        setIntervalClockId,
        clockStatus,
        setClockStatus,
        initialState,
        staticData,
        setStaticData,
        isBoardSettingsOpen,
        setIsBoardSettingsOpen,
        boardTab,
        setBoardTab
    }
    return <SoccerProvider.Provider value={values}>{children}</SoccerProvider.Provider>
}

export const useSoccer = () => {
    return useContext(SoccerProvider)
}