import React from 'react'
import styled from 'styled-components'
const Main = styled.div`
position: absolute;
background-color: #FF8D26;
top: 0;
left: 0;
width: 100%;
height: 25%;
img {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    height: 100%;
}
`
const Banner = () => {
    return (
        <Main>
            <img src="/assets/sports/tennis/banner.svg" alt="" />
        </Main>
    )
}

export default Banner