import React, { useEffect } from 'react'
import { useBasketball } from '../../../context/basketball'
import styled from 'styled-components'
import secondsToMinutes from '../../../utils/toMinutes'
import BasketballService from '../../../services/basketball.service'
import { useGlobal } from '../../../context/global'
const Times = () => {
    const { data, setData, setIntervalClockId, intervalClockId, clockStatus, setClockStatus } = useBasketball()
    const { socket, user } = useGlobal()
    const time = data.clock ? secondsToMinutes(data.clock) : '00:00'
    const startClock = () => {
        if (clockStatus === 'running') return
        if (!data.clock) return
        setClockStatus('running')
        BasketballService.handleClock({ newTime: data.clock, clock_status: 'running' }, user.token)
        socket.emit("update_results", { clock: data.clock, clock_status: 'running' })
        const clockInterval = setInterval(() => {
            if (data.clock === 0) clearInterval(clockInterval)
            setData(prevData => {
                return { ...prevData, clock: prevData.clock - 1 }
            })
        }, 1000)
        setIntervalClockId(clockInterval)
    }
    const pauseClock = () => {
        if (clockStatus === 'stopped' || clockStatus === 'reboot') return
        clearInterval(intervalClockId)
        BasketballService.handleClock({ newTime: data.clock || 60 * 12, clock_status: 'stopped' }, user.token)
        socket.emit("update_results", { clock: data.clock || 60 * 12, clock_status: 'stopped' })
        data.clock && setClockStatus('stopped')
    }
    useEffect(() => {
        if (data.clock === 0) {
            clearInterval(intervalClockId)
            setClockStatus('stopped')
        }
    }, [data])
    return (
        <Main>
            <Counter>
                <h4>{data.time || 1}T</h4>
                <h3>{time}</h3>
            </Counter>
            <Controller>
                <button className={clockStatus === 'running' ? 'start running' : 'start'} onClick={startClock}>Inicio</button>
                <button className={clockStatus === 'stopped' ? 'stop running' : 'stop'} onClick={pauseClock}>Pausa</button>
            </Controller>
        </Main>
    )
}

export default Times

const Main = styled.div`
width: calc(90% - 20px);
padding: 15px 10px;
margin: 0 auto;
display: flex;
background-color: #F8F8F8;
border: 1px solid #E5E5E5;
max-width: 500px;
border-radius: 10px;
align-items: center;
justify-content: center;
> * {
    min-width: 0;
}
`
const Counter = styled.div`
flex: 1;
text-align: center;
white-space: nowrap;
h4, h3 {
    margin: 0;
    font-weight: 400;
}
h4 {
    font-size: 20px;
}
h3 {
    font-size: 30px;
    margin-top: 10px;
}
`
const Controller = styled.div`
flex: 1.3;
text-align: center;
box-sizing: border-box;
white-space: nowrap;
button {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 19px 9px;
    margin: 0 3px;
    gap: 10px;
    width: 77px;
    height: 40px;
    font-family: "Roboto";
    background: #FFFFFF;
    border: 1px solid #FF8D26;
    border-radius: 21px;
    font-size: 14px;
}
button.running {
    font-weight: 700;
    background: #FF8D26;
    color: #FFFFFF;
}
`