import React, {useRef, useState, useEffect} from 'react'
import secondsToMinutes from '../../utils/toMinutes'
import styled, {css} from 'styled-components'
const Soccer = ({ data, parentContainer }) => {
    const time = parentContainer ? data.clock : data.clock + data.clock_seconds_diff  
    const [initialContainerSize, setInitialContainerSize] = useState({})
    const [isEditing, setIsEditing] = useState(false)
    const [containerStyle, setContainerStyle] = useState({
        top: '10px',
        left: '10px',
        translate: "translate(0, 0)",
        scale: 'scale(1)',
        scale100: ''
    });
    const [style, setStyle] = useState({})
    useEffect(() => {
      const design = data.lowerDesign
      const propType = {}
      if (!design) return
      switch (design.bandSize) {
        case 'l':
          propType.bandLeft = {height: '100%', width: '10px', marginLeft: design.isBandCorner ? '': '15px'}
          propType.bandRight = {height: '100%', width: '10px', marginRight: design.isBandCorner ? '': '15px'}
          break;
        case 'm':
          propType.bandLeft = {height: '80%', width: '10px', marginLeft: design.isBandCorner ? '': '8px'}
          propType.bandRight = {height: '80%', width: '10px', marginRight: design.isBandCorner ? '': '8px'}
          break;
        case 's':
          propType.bandLeft = {height: '65%', width: '10px', marginLeft: design.isBandCorner ? '': '8px'}
          propType.bandRight = {height: '65%', width: '10px',  marginRight: design.isBandCorner ? '': '8px'}
          break;
        case 'xs':
          propType.bandLeft = {height: '65%', width: '7px', marginLeft: design.isBandCorner ? '': '10px'}
          propType.bandRight = {height: '65%', width: '7px', marginRight: design.isBandCorner ? '': '10px'}
          break
        default:
          break;
      }
      if (design.isRect) {
        propType.angle = 0
      } else propType.angle = 20
      setStyle(propType)
    }, [data])

    const containerRef = useRef();
    const mainRef = useRef()

    useEffect(() => {
      const containerRect = containerRef.current.getBoundingClientRect()
      return setInitialContainerSize({
        height: containerRect.height,
        width: containerRect.width
      })
  }, [containerRef])

    const minimumScale = 0.001;

    useEffect(() => {
      const screenWidth = parentContainer ? mainRef.current.parentElement.getBoundingClientRect().width - 70 : mainRef.current.getBoundingClientRect().width - 70;
      const desiredWidthPercentage = 0.2 + (data.lower_size / 10) * 0.4; // calculate desired width as a percentage of screen
      const scale = (desiredWidthPercentage * screenWidth) / initialContainerSize.width;
      console.log(scale)
      setContainerStyle({
        ...containerStyle,
        ...data.lowerPosition,
        scale: `scale(${scale})`,
        scale100: `scale(${( 0.95 *  screenWidth) / initialContainerSize.width})`,
      })
    }, [data.lower_size, initialContainerSize, containerRef])
    console.log(containerStyle)
    useEffect(() => {
      if (!data.lowerPosition) return

      setContainerStyle(prevData => ({...prevData, translate: 'translate(0, 0)', ...data.lowerPosition}))
    }, [data.lowerPosition])
    function useOutsideAlerter(ref) {
        useEffect(() => {
          /**
           * Alert if clicked on outside of element
           */
          function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
              setIsEditing(false)
            }
          }
          // Bind the event listener
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
          };
        }, [ref]);
      }
      useOutsideAlerter(containerRef)
      const handleResize = (event, direction) => {
        const screenWidth = parentContainer ? mainRef.current.parentElement.getBoundingClientRect().width - 70 : mainRef.current.getBoundingClientRect().width - 70;
          event.preventDefault();
          event.stopPropagation()
          if (!isEditing) return
          const container = containerRef.current
          if (container) {
            const containerRect = container.getBoundingClientRect();
            const containerWidth = containerRect.width;
            const containerHeight = containerRect.height;
          
            const handleDrag = (event) => {
              const clientX = event.type === "mousemove" ? event.clientX : event.touches[0].clientX;
              const clientY = event.type === "mousemove" ? event.clientY : event.touches[0].clientY;
              
              
              const newWidth = direction.includes("right")
              ? clientX - containerRect.left
              : containerWidth + containerRect.left - clientX;
            const newHeight = direction.includes("bottom")
              ? clientY - containerRect.top
              : containerHeight + containerRect.top - clientY;
                const aspectRatio = initialContainerSize.width / initialContainerSize.height;
                  
                  if (newWidth / newHeight > aspectRatio) {
                    const calculatedScale = newWidth / initialContainerSize.width;
                    const maxScale = screenWidth / initialContainerSize.width;
                    let newScale = Math.min(calculatedScale, maxScale);
                    if (newScale < minimumScale) {
                      newScale = minimumScale;
                    }
                    setContainerStyle({
                      ...containerStyle,
                      scale: `scale(${newScale})`,
                    });
                  } else {
                    const calculatedScale = newHeight / initialContainerSize.height;
                    
                    // Prevent scaling beyond screen width
                    const maxScale = screenWidth / initialContainerSize.width;
                    let newScale = Math.min(calculatedScale, maxScale);
                    if (newScale < minimumScale) {
                      newScale = minimumScale;
                    }
                    setContainerStyle({
                      ...containerStyle,
                      scale: `scale(${newScale})`,
                    });
                  
                  }
            }
            const handleDragEnd = () => {
              document.removeEventListener("mousemove", handleDrag);
              document.removeEventListener("touchmove", handleDrag)
              document.removeEventListener("mouseup", handleDragEnd);
              document.removeEventListener("touchend", handleDragEnd)
            };
            document.addEventListener("mousemove", handleDrag);
            document.addEventListener("mouseup", handleDragEnd);
            document.addEventListener("touchmove", handleDrag)
            document.addEventListener("touchend", handleDragEnd)
          }
        };
  
        const handleDragStart = (event) => {
          event.preventDefault();
          if (!isEditing) return
          const container = containerRef.current
          if (container) {
            const containerRect = container.getBoundingClientRect();
            const offsetX = event.type === "mousedown" ?  event.clientX - containerRect.left: event.touches[0].clientX - containerRect.left;
            const offsetY = event.type === "mousedown" ? event.clientY - containerRect.top :event.touches[0].clientY - containerRect.top ;
            const handleDrag = (event) => {
              event.preventDefault()
              const clientX = event.type === "mousemove" ? event.clientX : event.touches[0].clientX;
              const clientY = event.type === "mousemove" ? event.clientY : event.touches[0].clientY;
              const scale = Number(containerStyle.scale.replace("scale(", "").replace(")", ""))
              const newLeft = clientX - (offsetX / scale);
              const newTop = clientY - (offsetY / scale);
              setContainerStyle({
                ...containerStyle,
                right: 0,
                bottom: 0,
                left: 0,
                top: 0,
                translate: `translate(${newLeft}px, ${newTop}px)`,
              });
            };
      
            const handleDragEnd = () => {
              document.removeEventListener("mousemove", handleDrag);
              document.removeEventListener("touchmove", handleDrag)
              document.removeEventListener("mouseup", handleDragEnd);
              document.removeEventListener("touchend", handleDragEnd)
            };
      
            document.addEventListener("mousemove", handleDrag);
            document.addEventListener("touchmove", handleDrag, {passive: false})
            document.addEventListener("touchend", handleDragEnd)
            document.addEventListener("mouseup", handleDragEnd);
          }
        };
    return (
      <Main
      ref={mainRef}
      style={parentContainer ? 
        {}
        : 
        {width: '100vw', height: '100vh'}
      }
      >
          <Container
              ref={containerRef}
              style={
                parentContainer ?
                {position: 'absolute', left: '50%', top: '50%', transform: `translate(-50%, -50%) ${containerStyle.scale100}`}
                :
                {transform: `${containerStyle.translate} ${containerStyle.scale}`, ...containerStyle}
              }
              angle={style.angle}
              design={data.fullDesign}
              onMouseDown={handleDragStart}
              onTouchStart={handleDragStart}
              className={isEditing ? 'editing' : ''}
              onDoubleClick={() => setIsEditing(!isEditing)}
              background_disabled={!data.is_showing_results_background}
              >
              {
                isEditing &&
                <>
                      <TopLeftHandle onTouchStart={(e) => handleResize(e, "top-left")} onMouseDown={(e) => handleResize(e, "top-left")} />
                      <TopRightHandle onTouchStart={(e) => handleResize(e, "top-right")} onMouseDown={(e) => handleResize(e, "top-right")} />
                      <BottomLeftHandle onTouchStart={(e) => handleResize(e, "bottom-left")} onMouseDown={(e) => handleResize(e, "bottom-left")} />
                      <BottomRightHandle onTouchStart={(e) => handleResize(e, "bottom-right")} onMouseDown={(e) => handleResize(e, "bottom-right")} />
                      </>
                  }
              <div className='parallelogram_left'>
                  <div className='band' 
                  style={
                    {...style.bandLeft, background: data.teams?.find(obj => obj.state === 'team1')?.color || 'rgba(0,0,0,0)'}}>
                  </div>
                  <span>
                      {data.teams ? (data.teams.find(obj => obj.state === 'team1').name || 'TEA') : 'TEA'}
                  </span>
              </div>
              <div className='results'>
                  <div>
                    <span>
                      {data.teams ? (data.teams.find(obj => obj.state === 'team1').goals || 0) : 0}
                    </span>
                  </div>
                  <div className='separator'></div>
                  <div>
                    <span>
                      {data.teams ? (data.teams.find(obj => obj.state === 'team2').goals || 0) : 0}
                    </span>
                  </div>
              </div>
              <div className='parallelogram_right'>
                  <span>
                      {data.teams ? (data.teams.find(obj => obj.state === 'team2').name || 'TEA') : 'TEA'}
                  </span>
                  <div className='band' 
                        style={
                          {...style.bandRight, background: data.teams?.find(obj => obj.state === 'team2')?.color || 'rgba(0,0,0,0)'}}></div>
              </div>
              <div className='time'>
                  <span>
                  {data.clock_status === 'running' ? secondsToMinutes(time) : secondsToMinutes(data.clock)}
                  </span>
              </div>
              {
                (data.extra_minutes > 0 && data.show_extra_minutes) &&
                <div className='extra_time'>
                      <span>
                          +{data.extra_minutes}
                      </span>
                  </div>
              }
          </Container>
      </Main>
    )
}

export default Soccer

const Main = styled.div`
position: relative;
width: 90%;
height: 100%;
display: block;
overflow: hidden;
`

const Container = styled.div`
position: absolute;
height: 46px;
font-family: 'Poppins';
background-color: rgba(0,0,0,0);
cursor: pointer;
width: fit-content;
display: flex;
&.editing {
    cursor: move;
    ::after {
        content: "";
        position: absolute;
        left: 0px;
        top: 0px;
        border: 1px solid #30BCED;
        width: 100%;
        ${props => !props.design?.isRect && css`
            display: none;
        `}
        height: 100%;
    }
}
.parallelogram_left {
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    width: 130px;
    transform: skewX(${props => props.angle}deg);
    z-index: 5;
    height: 100%;
    background-color: #000000;
    span {
      flex: 1;
      text-align: center;
      display: block;
      transform: skewX(-${props => props.angle}deg);
      color: #FFFFFF;
      z-index: 20;
      text-transform: uppercase;
      font-size: 26px;
    }
  }
  .parallelogram_right {
    width: 130px;
    height: 100%;
    transform: skewX(-${props => props.angle}deg);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
    background-color: #000000;
    span {
        flex: 1;
        text-align: center;
        display: block;
        transform: skewX(${props => props.angle}deg);
        color: #FFFFFF;
        z-index: 20;
        text-transform: uppercase;
        font-size: 26px;
    }
    img {
        position: absolute;
        /* height: 100%; */
    }
}
.results {
  height: 100%;
  display: flex;
  position: relative;
  z-index: -1;
  background-color: #30BCED;
  box-shadow: -50px 0 0 0 #30BCED, 50px 0 0 0 #30BCED;
  .separator {
    display: block;
    padding: 0;
    width: 2px;
    height: 100%;
    background-color: black;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  > div {
      width: 90px;
      &:first-child {
        transform: skewX(${props => props.angle}deg);
        span {
          display: block;
          transform: skewX(-${props => props.angle}deg);
        }
      }
      &:last-child {
        transform: skewX(-${props => props.angle}deg);
        span {
          display: block;
          transform: skewX(${props => props.angle}deg);
        }
      }
      z-index: 10;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 34px;
      font-family: 'Poppins';
  }
}
.time {
    background-color: white;
    transform: skewX(-${props => props.angle}deg);
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F4F4F4;
    span {
      display: block;
      transform: skewX(${props => props.angle}deg);
      color: #000000;
      z-index: 20;
      text-transform: uppercase;
      font-size: 26px;
    }
  }
  .extra_time {
    height: 100%;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: red;
    transform: skewX(-${props => props.angle}deg);
    span {
        display: block;
        transform: skewX(${props => props.angle}deg);
        color: #FFFFFF;
        z-index: 20;
        text-transform: uppercase;
        font-size: 27px;
        font-weight: 500;
    }
    img {
        /* height: 100%; */
        position: absolute;
    }
}
`

const ResizeHandle = styled.div`
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: white;
  border: 1px solid #30BCED;
  border-radius: 50%;
  z-index: 100;
`;

const TopLeftHandle = styled(ResizeHandle)`
  top: -7px;
  left: -7px;
  cursor: nwse-resize;
`;

const TopRightHandle = styled(ResizeHandle)`
  top: -7px;
  right: -7px;
  cursor: nesw-resize;
`;

const BottomLeftHandle = styled(ResizeHandle)`
  bottom: -7px;
  left: -7px;
  cursor: nesw-resize;
`;

const BottomRightHandle = styled(ResizeHandle)`
  bottom: -7px;
  right: -7px;
  cursor: nwse-resize;
`;