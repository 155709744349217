import React from 'react'
import styled from 'styled-components'
import { useGlobal } from '../../../../context/global'
import { useRugby } from '../../../../context/rugby'
import RugbyService from '../../../../services/rugby.service'
const Time = () => {
    const { data, setData, intervalClockId, setClockStatus } = useRugby()
    const { socket, user } = useGlobal()
    const substractTime = () => {
        if (data.time) {
            setData({ ...data, time: data.time - 1 })
            RugbyService.handleMatch({ time: data.time - 1 }, user.token)
            socket.emit("update_results", { time: data.time - 1 })
        } else {
            setData({ ...data, time: 1 })
            RugbyService.handleMatch({ time: 1 }, user.token)
            socket.emit("update_results", { time: 1 })
        }
    }
    const addTime = () => {
        setClockStatus('stopped')
        clearInterval(intervalClockId)
        const secondTime = 40 * 60
        let clock
        if (data.time + 1 > 2) clock = 0
        else clock = secondTime
        if (data.time) {
            setData({ ...data, time: data.time + 1, clock: clock })
            RugbyService.handleMatch({ time: data.time + 1 }, user.token)
            RugbyService.handleClock({ newTime: clock, clock_status: 'stopped' }, user.token)
            socket.emit("update_results", { clock: clock, time: data.time + 1 })
        }
        else {
            setData({ ...data, time: 2, clock: secondTime })
            RugbyService.handleMatch({ time: 2 }, user.token)
            RugbyService.handleClock({ newTime: secondTime, clock_status: 'stopped' }, user.token)
            socket.emit("update_results", { clock: secondTime, time: 2, clock_status: 'stopped' })
        }
    }
    return (
        <Main>
            <h4>Tiempo</h4>
            <div className="info">
                <div className='minus' onClick={substractTime}>
                    -
                </div>
                <span>{data.time || 1}</span>
                <div className='plus' onClick={addTime}>
                    +
                </div>
            </div>
        </Main>
    )
}

export default Time

const Main = styled.div`
background-color: #F8F8F8;
border: 1px solid #E5E5E5;
border-radius: 10px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
padding: 20px;
max-width: 500px;
width: calc(90% - 40px);
margin: auto;
h4 {
    margin: 0;
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: 400;
}
.info {
    display: flex;
    justify-content: center;
    align-items: center;
    span {
        font-size: 30px;
        margin: 0 15px;
    }
    div {
        width: 30px;
        height: 30px;
        border: 1px solid #159800;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        border-radius: 50%;
        user-select: none;
    }
    div.plus {
        background-color: #159800;
        color: #ffffff;
        line-height: 20px;
    }
    div.minus {
        background-color: #ffffff;
        display: flex;
        justify-content: center;
    }
}
`