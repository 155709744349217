import React, {useRef, useState, useEffect} from 'react'
import secondsToMinutes from '../../utils/toMinutes'
import styled, {css} from 'styled-components'

const Basketball = ({ data }) => {
    const time = (data.clock - data.clock_seconds_diff) > 0 ? data.clock - data.clock_seconds_diff : 0
    const [isEditing, setIsEditing] = useState(false)
    const [containerStyle, setContainerStyle] = useState({
        transform: "translate(0, 0) scale(1)",
    });
    
    function useOutsideAlerter(ref) {
        useEffect(() => {
          /**
           * Alert if clicked on outside of element
           */
          function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
              setIsEditing(false)
            }
          }
          // Bind the event listener
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
          };
        }, [ref]);
      }
      const containerRef = useRef();
      useOutsideAlerter(containerRef)
      const handleResize = (event, direction) => {
          event.preventDefault();
          event.stopPropagation()
          if (!isEditing) return
          const container = containerRef.current
          if (container) {
            const containerRect = container.getBoundingClientRect();
            const containerWidth = containerRect.width;
            const containerHeight = containerRect.height;
          
            const handleDrag = (event) => {
              const clientX = event.type === "mousemove" ? event.clientX : event.touches[0].clientX;
              const clientY = event.type === "mousemove" ? event.clientY : event.touches[0].clientY;
              
              const screenWidth = window.innerWidth - 70;
              
              const newWidth = direction.includes("right")
              ? clientX - containerRect.left
              : containerWidth + containerRect.left - clientX;
            const newHeight = direction.includes("bottom")
              ? clientY - containerRect.top
              : containerHeight + containerRect.top - clientY;
                const minimumScale = 0.7;
                const aspectRatio = 445 / 46;
                  const translateEndIndex = containerStyle.transform.indexOf(' scale')
                  const translate = containerStyle.transform.slice(0, translateEndIndex)
                  
                  if (newWidth / newHeight > aspectRatio) {
                    const calculatedScale = newWidth / 445;
                    const maxScale = screenWidth / 445;
                    let newScale = Math.min(calculatedScale, maxScale);
                    if (newScale < minimumScale) {
                      newScale = minimumScale;
                    }
                    setContainerStyle({
                      ...containerStyle,
                      transform: `${translate} scale(${newScale})`,
                    });
                  } else {
                    const calculatedScale = newHeight / 46;
                    
                    // Prevent scaling beyond screen width
                    const maxScale = screenWidth / 445;
                    let newScale = Math.min(calculatedScale, maxScale);
                    if (newScale < minimumScale) {
                      newScale = minimumScale;
                    }
                    setContainerStyle({
                      ...containerStyle,
                      transform: `${translate} scale(${newScale})`,
                    });
                  
                  }
            }
            const handleDragEnd = () => {
              document.removeEventListener("mousemove", handleDrag);
              document.removeEventListener("touchmove", handleDrag)
              document.removeEventListener("mouseup", handleDragEnd);
              document.removeEventListener("touchend", handleDragEnd)
            };
            document.addEventListener("mousemove", handleDrag);
            document.addEventListener("mouseup", handleDragEnd);
            document.addEventListener("touchmove", handleDrag)
            document.addEventListener("touchend", handleDragEnd)
          }
        };
  
        const handleDragStart = (event) => {
          event.preventDefault();
          if (!isEditing) return
          const container = containerRef.current
          if (container) {
            const containerRect = container.getBoundingClientRect();
            const offsetX = event.type === "mousedown" ?  event.clientX - containerRect.left: event.touches[0].clientX - containerRect.left;
            const offsetY = event.type === "mousedown" ? event.clientY - containerRect.top :event.touches[0].clientY - containerRect.top ;
            const handleDrag = (event) => {
              event.preventDefault()
              const clientX = event.type === "mousemove" ? event.clientX : event.touches[0].clientX;
              const clientY = event.type === "mousemove" ? event.clientY : event.touches[0].clientY;
              const scale = Number(containerStyle.transform.split(" ")[2].replace("scale(", "").replace(")", ""))
              const newLeft = clientX - (offsetX / scale);
              const newTop = clientY - (offsetY / scale);
              setContainerStyle({
                ...containerStyle,
                bottom: 0,
                left: 0,
                top: 0,
                transform: `translate(${newLeft}px, ${newTop}px) ${containerStyle.transform.split(" ")[2]}`,
              });
            };
      
            const handleDragEnd = () => {
              document.removeEventListener("mousemove", handleDrag);
              document.removeEventListener("touchmove", handleDrag)
              document.removeEventListener("mouseup", handleDragEnd);
              document.removeEventListener("touchend", handleDragEnd)
            };
      
            document.addEventListener("mousemove", handleDrag);
            document.addEventListener("touchmove", handleDrag, {passive: false})
            document.addEventListener("touchend", handleDragEnd)
            document.addEventListener("mouseup", handleDragEnd);
          }
        };
    return (
        <Container
            ref={containerRef}
            style={containerStyle}
            onMouseDown={handleDragStart}
            onTouchStart={handleDragStart}
            className={isEditing ? 'editing' : ''}
            onDoubleClick={() => setIsEditing(!isEditing)}
            background_disabled={!data.is_showing_results_background}
        >
            {
                    isEditing &&
                    <>
                    <TopLeftHandle onTouchStart={(e) => handleResize(e, "top-left")} onMouseDown={(e) => handleResize(e, "top-left")} />
                    <TopRightHandle onTouchStart={(e) => handleResize(e, "top-right")} onMouseDown={(e) => handleResize(e, "top-right")} />
                    <BottomLeftHandle onTouchStart={(e) => handleResize(e, "bottom-left")} onMouseDown={(e) => handleResize(e, "bottom-left")} />
                    <BottomRightHandle onTouchStart={(e) => handleResize(e, "bottom-right")} onMouseDown={(e) => handleResize(e, "bottom-right")} />
                    </>
                }
            <div className='parallelogram_left'>
                <span>
                    {data.teams ? (data.teams.find(obj => obj.state === 'team1').name || 'TEA') : 'TEA'}
                </span>
                <img  src='/assets/results_lower/parallelogram_left.svg' alt=''/>
            </div>
            <div className='parallelogram_right'>
            <span>
                    {data.teams ? (data.teams.find(obj => obj.state === 'team2').name || 'TEA') : 'TEA'}
                </span>
                <img  src='/assets/results_lower/parallelogram_right.svg' alt=''/>
            </div>
            <div className='results'>
                <div>
                    {data.teams ? (data.teams.find(obj => obj.state === 'team1').points || 0) : 0}
                </div>
                <img src='/assets/results_lower/results.svg' alt=''/>
                <div>
                {data.teams ? (data.teams.find(obj => obj.state === 'team2').points || 0) : 0}
                </div>
            </div>
            <div className='time'>
                <span>
                    {data.time || 1}T
                </span>
                <span>
                {data.clock_status === 'running' ? secondsToMinutes(time) : secondsToMinutes(data.clock)}
                </span>
                <img src='/assets/results_lower/time_longer.svg' alt=''/>
            </div>
            <img className='separator' src='/assets/results_lower/separator.svg' alt=''/>
        </Container>
    )
}

export default Basketball



const Container = styled.div`
position: absolute;
bottom: 40px;
left: 40px;
height: 46px;
width: 501px;
font-family: 'Poppins';
background-color: rgba(0,0,0,0);
transform: translate(0,0) scale(1);
cursor: pointer;
${props => props.background_disabled === true && css`
  img {
    opacity: 0;
  }
`}
> * {
    position: absolute;
}
&.editing {
    cursor: move;
    ::after {
        content: "";
        position: absolute;
        left: 0px;
        top: 0px;
        border: 1px solid #30BCED;
        width: 100%;
        height: 100%;
    }
}
.background {
    width: 100vh;
    position: absolute;
    height: 100vw;
    object-fit: cover;
}
.parallelogram_left {
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    height: 46px;
    width: 103px;
    z-index: 5;
    span {
        color: #FFFFFF;
        z-index: 20;
        text-transform: uppercase;
        font-size: 26px;
    }
    img {
        position: absolute;
        height: 100%;
    }
}
.parallelogram_right {
    left: 231px;
    height: 46px;
    width: 103px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
    span {
        color: #FFFFFF;
        z-index: 20;
        text-transform: uppercase;
        font-size: 26px;
    }
    img {
        position: absolute;
        height: 100%;
    }
}
.results {
    height: 46px;
    left: 83px;
    padding: 0 40px;
    width: calc(168px - 80px);
    display: flex;
    gap: 20px;
    > div {
        z-index: 10;
        flex: 1;
        display: flex;
        margin-top: 3px;
        align-items: center;
        justify-content: center;
        font-size: 34px;
        font-family: 'Poppins';
    }
    img {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}
.time {
    height: 46px;
    width: 186px;
    left: 315px;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
        color: #000000;
        z-index: 20;
        text-transform: uppercase;
        font-size: 26px;
        margin-right: 20px;
    }
    img {
        height: 100%;
        position: absolute;
        top: 0;
    }
}
.separator {
    height: 46px;
    left: 165px;
}
`

const ResizeHandle = styled.div`
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: white;
  border: 1px solid #30BCED;
  border-radius: 50%;
  z-index: 100;
`;

const TopLeftHandle = styled(ResizeHandle)`
  top: -7px;
  left: -7px;
  cursor: nwse-resize;
`;

const TopRightHandle = styled(ResizeHandle)`
  top: -7px;
  right: -7px;
  cursor: nesw-resize;
`;

const BottomLeftHandle = styled(ResizeHandle)`
  bottom: -7px;
  left: -7px;
  cursor: nesw-resize;
`;

const BottomRightHandle = styled(ResizeHandle)`
  bottom: -7px;
  right: -7px;
  cursor: nwse-resize;
`;