import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import UsersService from "../services/users.service";

export default function EmailVerified() {
    const navigate = useNavigate()
    const {token} = useParams()
    useEffect(() => {
        async function validate_email() {
            const response =  await UsersService.validate_email(token)
            console.log(response)
        }
        validate_email()
    }, [])
    return (
        <Main>
            <AppBar/>
            <MailVerified>
                <img src="/assets/aiomix.svg" alt=""/>
                <div>
                    <span>Email verificado correctamente!</span>
                    <button type="submit" className="btn"
                    onClick={() => navigate('/')}
                    >Go Login</button>
                </div>
            </MailVerified>
        </Main>
    )
}

const Main = styled.section`
background-color: #FFF;
max-height: 100vh;
height: 100vh;
`

const AppBar = styled.div`
height: 15%;
background-color: #000000;
position: relative;
`
const MailVerified = styled.div`
span {
    max-width: 50%;
    text-align: center;
    color: #818181;
    display: block;
}
width: 100%;
height: 85%;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
gap: 20%;
> div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 20%;
}
.btn {
    text-transform: uppercase;
    text-decoration: none;
    padding: 10px 30px;
    border: 1px solid;
    background-color: #ccc;
    border-radius: 1000px;
    display: inline-block;
    transition: all .2s;
    position: relative;
    cursor: pointer;
}

.btn:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(27, 27, 27, .5);
}

.btn::after {
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 100px;
    top: 0;
    left: 0;
    position: absolute;
    z-index: -1;
    transition: all .3s;
    opacity: 0.5;
}

.btn:hover::after {
    background-color: rgb(100, 100, 100);
    transform: scaleX(1.4) scaleY(1.5);
    opacity: 0;
}
`