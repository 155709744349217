import React, { useState, useContext } from "react"
const HandballProvider = React.createContext()

export const HandballContext = ({ children }) => {
    const [selectedSection, setSelectedSection] = useState('settings')
    const [isSidebarOpen, setIsSidebarOpen] = useState(false)
    const initialState = { teams: [{ name: 'team1', state: 'team1' }, { name: 'team2', state: 'team2' }], clock: 30 * 60, duration: 30 * 60 }
    const [data, setData] = useState(initialState)
    const [staticData, setStaticData] = useState(initialState)
    const [intervalClockId, setIntervalClockId] = useState(0)
    const [clockStatus, setClockStatus] = useState('reboot')
    const values = {
        selectedSection,
        setSelectedSection,
        isSidebarOpen,
        setIsSidebarOpen,
        data,
        setData,
        intervalClockId,
        setIntervalClockId,
        clockStatus,
        setClockStatus,
        initialState,
        staticData,
        setStaticData
    }
    return <HandballProvider.Provider value={values}>{children}</HandballProvider.Provider>
}

export const useHandball = () => {
    return useContext(HandballProvider)
}