import axios from 'axios'

// console.log(process.env)
const http = axios.create({
    // baseURL: 'http://3.99.216.205:5000',
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        "Content-type": "application/json"
    },
})

export default http;