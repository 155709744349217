import React from 'react'
import styled from 'styled-components'
import { useRugby } from '../../context/rugby'
import Teams from './body/teams'
import Settings from './body/settings/settings'
import Points from './body/points/points'
const Body = () => {
    const { selectedSection } = useRugby()
    return (
        <Main>
            <Teams />
            {
                selectedSection === 'settings' &&
                <Settings />
            }
            {
                selectedSection === 'points' &&
                <Points />
            }
        </Main>
    )
}

export default Body

const Main = styled.div`
flex: 1;
display: flex;
justify-content: center;
flex-direction: column;
`
