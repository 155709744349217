import React from 'react'
import styled from 'styled-components'
import { useBasketball } from '../../../../context/basketball'
import { useGlobal } from '../../../../context/global'
import BasketballService from '../../../../services/basketball.service'

const Pointstable = () => {
    const { data, setData } = useBasketball()
    const { socket, user } = useGlobal()
    const addPoint = (team, amount) => {
        const teamData = data.teams.filter(object => object.state !== team)
        const oldTeam = data.teams.find(object => object.state === team)
        oldTeam.points = oldTeam.points ? oldTeam.points + amount : amount;
        const newData = { ...data, teams: [...teamData, oldTeam] }
        setData(newData)
        BasketballService.handleMatch({ teams: [...teamData, oldTeam] }, user.token)
        socket.emit("update_results", { teams: [...teamData, oldTeam] })
    }
    const substractPoint = (team, amount) => {
        const oldTeam = data.teams.find(object => object.state === team)
        if (!oldTeam.points) return
        const teamData = data.teams.filter(object => object.state !== team)
        oldTeam.points = oldTeam.points - amount < 0 ? 0 : oldTeam.points - amount
        const newData = { ...data, teams: [...teamData, oldTeam] }
        setData(newData)
        BasketballService.handleMatch({ teams: [...teamData, oldTeam] }, user.token)
        socket.emit("update_results", { teams: [...teamData, oldTeam] })
    }
    const resetTable = () => {
        let teams = data.teams.map(object => {
            return { ...object, points: 0 }
        })
        setData({ ...data, teams })
        BasketballService.handleMatch({ teams: teams }, user.token)
        socket.emit("update_results", { teams: teams })
    }
    return (
        <Main>
            <Counter>
                <h4>{data.teams.find(object => object.state === 'team1')?.points || 0}</h4>
                <div className="buttons">
                    <div className="row">
                        <div className='plus' onClick={() => addPoint('team1', 1)}>
                            <span>
                                +1
                            </span>
                        </div>
                        <div className='plus' onClick={() => addPoint('team1', 2)}>
                            <span>
                                +2
                            </span>
                        </div>
                        <div className='plus' onClick={() => addPoint('team1', 3)}>
                            <span>
                                +3
                            </span>
                        </div>
                    </div>
                    <div className="row">
                        <div className='minus' onClick={() => substractPoint('team1', 1)} >
                            <span>
                                -1
                            </span>
                        </div>
                        <div className='minus' onClick={() => substractPoint('team1', 2)} >
                            <span>
                                -2
                            </span>
                        </div>
                        <div className='minus' onClick={() => substractPoint('team1', 3)} >
                            <span>
                                -3
                            </span>
                        </div>
                    </div>
                </div>
            </Counter>
            <Reset>
                <h4>Puntos</h4>
                <button onClick={resetTable}>Reset</button>
            </Reset>
            <Counter>
                <h4>{data.teams.find(object => object.state === 'team2')?.points || 0}</h4>
                <div className="buttons">
                    <div className="row">
                        <div className='plus' onClick={() => addPoint('team2', 1)}>
                            <span>
                                +1
                            </span>
                        </div>
                        <div className='plus' onClick={() => addPoint('team2', 2)}>
                            <span>
                                +2
                            </span>
                        </div>
                        <div className='plus' onClick={() => addPoint('team2', 3)}>
                            <span>
                                +3
                            </span>
                        </div>
                    </div>
                    <div className="row">
                        <div className='minus' onClick={() => substractPoint('team2', 1)} >
                            <span>
                                -1
                            </span>
                        </div>
                        <div className='minus' onClick={() => substractPoint('team2', 2)} >
                            <span>
                                -2
                            </span>
                        </div>
                        <div className='minus' onClick={() => substractPoint('team2', 3)} >
                            <span>
                                -3
                            </span>
                        </div>
                    </div>
                </div>
            </Counter>
        </Main>
    )
}

export default Pointstable

const Main = styled.div`
width: calc(90% - 10px);
padding: 15px 5px;
margin: 20px auto;
margin-bottom: 0;
display: flex;
background-color: #F8F8F8;
border: 1px solid #E5E5E5;
max-width: 500px;
border-radius: 10px;
justify-content: center;
gap: 10%;
`
const Counter = styled.div`
flex: 1;
h4 {
    text-align: center;
    margin: 0;
    font-size: 64px;
    font-weight: 400;
}
.buttons {
    .row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        :first-child {
            margin-bottom: 10px;
        }
        div {
            width: 40px;
            height: 40px;
            border: 1px solid #FF8D26;
            border-radius: 50%;
            cursor: pointer;
            box-sizing: border-box;
            gap: 10px;
            user-select: none;

/* Auto layout */

display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
            span {
                font-weight: 500;
                font-size: 14px;
            }
        }
        .minus {
                background-color: #FFFFFF;
            }
            .plus {
                background-color: #FF8D26;
                color: #FFFFFF;
                float: right;
            }
    }
}
`
const Reset = styled.div`
text-align: center;
margin-top: 10px;
position: absolute;
left: 50%;
transform: translateX(-50%);
h4 {
    margin: 0;
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 7px;
    font-weight: 700;
}
button {
    margin: 0;
    font-family: "Roboto";
    font-weight: 400;
    padding: 5px 10px;
    border: none;
    border-radius: 23px;
    background-color: #E14343;
    color: #FFFFFF;
}
`