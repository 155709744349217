import React from 'react'
import Pointstable from './points.table'
import Times from './times'

const Points = () => {
    return (
        <>
            <Times />
            <Pointstable />
        </>
    )
}

export default Points