import React, { useEffect } from 'react'
import styled from 'styled-components'
import Banner from '../../components/rugby/banner'
import Body from '../../components/rugby/body'
import Footer from '../../components/rugby/footer'
import Sidebar from '../../components/rugby/sidebar'
import { useParams } from 'react-router-dom'
import { useRugby } from '../../context/rugby'
import ShareService from '../../services/share.service'
import { useGlobal } from '../../context/global'


const Rugby = () => {
    document.querySelector('html').style.setProperty('--scrollbar-background-thumb-hover', '#117a00');
    document.querySelector('html').style.setProperty('--scrollbar-background-thumb', '#159800');
    const { sport } = useParams()
    const {user} = useGlobal()
    const { setData, setClockStatus, setStaticData, setIntervalClockId, intervalClockId } = useRugby()
    useEffect(() => {
        clearInterval(intervalClockId)
        ShareService.getMatch(sport, user.user._id)
            .then(response => {
                if (!response.data.content) return
                const isClockRunning = response.data.content.clock_status
                const clock_seconds = response.data.content.clock
                const diff = response.data.content.clock_seconds_diff
                const newData = { ...response.data?.content, clock: isClockRunning === 'running' ? (diff + clock_seconds) : clock_seconds }
                setData(newData)
                setStaticData(newData)
                if (response.data.content?.clock_status) setClockStatus(response.data.content.clock_status)
                if (response.data.content?.clock_status === 'running') {
                    const interval_id = window.setInterval(function () { }, Number.MAX_SAFE_INTEGER);
                    for (let i = 1; i < interval_id; i++) {
                        window.clearInterval(i);
                    }
                    const clockInterval = setInterval(() => {
                        setData(prevData => {
                            const new_clock = prevData.clock + 1
                            if (new_clock > 18000) {
                                clearInterval(clockInterval)
                                setClockStatus('stopped')
                                return {...prevData, clock_status: 'stopped'}
                            }
                            else return { ...prevData, clock: new_clock }
                        })
                    }, 1000)
                    setIntervalClockId(clockInterval)
                }
            })
            .catch(error => {
                console.log(error)
            })
    }, [])
    return (
        <Main>
            <Banner />
            <Body />
            <Footer />
            <Sidebar />
        </Main>
    )
}

export default Rugby

const Main = styled.div`
width: 100%;
height: 100vh;
display: flex;
flex-direction: column;
`