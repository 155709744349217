import styled, { css } from "styled-components";
import React, { useState, useRef, useEffect, useMemo } from "react";
import deepCompare from "../../../utils/deepCompare";
import SoccerFull from "../../../pages/viewer/Soccer";
import SoccerLower from "../../../pages/viewer_lower/Soccer";
import { useSoccer } from "../../../context/soccer";

export default function InputDropdown({
    selectedOptionHeight,
    options,
    defaultOptionHeight,
    label,
    selectedOption,
    onChangeSelectedOption,
    designType,
    type
}) {
    const {data} = useSoccer()
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const InputRef = useRef(null);
    useEffect(() => {
        function handleClickOutside(event) {
            if (InputRef.current && !InputRef.current?.contains(event.target)) {
                setDropdownVisible(false)
            }
          }
          // Bind the event listener
          document.addEventListener('mousedown', handleClickOutside);
          return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
          };
    }, [InputDropdown])
    const dropdownHeight = useMemo(() => {
        const numOptions = options.length;
        const sum = numOptions === 2 ? 1 : numOptions === 1 ? 2 : 0
        const optionHeight = defaultOptionHeight + sum; // Set the height of each option here
        const calculatedHeight = numOptions * optionHeight;
        const finalHeight = calculatedHeight
         > 200 ? 200 : calculatedHeight;
        return isDropdownVisible ? finalHeight : 0
      }, [isDropdownVisible, defaultOptionHeight, options.length]);
    
    const renderItem = (item, idx) => {
        const isSelected = deepCompare(item, selectedOption)
        const optionData = {...data, [designType]: item}
        return (
        <OptionItem style={{height: `${defaultOptionHeight}px`}} key={idx} isSelected={isSelected} onClick={() => onChangeSelectedOption(item)}>
            {
                type === 'full' ?
                <SoccerFull data={optionData} parentContainer={true} isNotBackgroundVisible={true}/>
                :
                <SoccerLower data={optionData} parentContainer={true} />
            }
            {
                isSelected &&
                <img className="check" src="/assets/common/check.svg" alt=""/>
            }
        </OptionItem>
        );
    };
    return (
        <Main>
            {
                label &&
                <label>{label}</label>
            }
            <DropdownContainer 
            ref={InputRef}
            >
                    <SelectorItem
                    style={
                        type === 'full' ?
                        {height: `${selectedOptionHeight}px`}
                        :
                        {height: `${selectedOptionHeight}px`}
                    }
                    onClick={() => setDropdownVisible(!isDropdownVisible)}
                    dropDownOpen={isDropdownVisible}
                    >
                            {
                                type === 'full' ?
                                <>
                                <div className="preview">
                                    <SoccerFull data={data} parentContainer={true}/>
                                </div>
                                </>
                                :
                                <SoccerLower data={data} parentContainer={true}/>
                            }
                        <img className="arrow" alt="" src="/assets/common/sidebar/arrow_down.svg"/>
                    </SelectorItem>
                    <DropdownList 
                    style={
                        { height: `${dropdownHeight}px`, top: `${selectedOptionHeight + 2}px` }
                    }
                    >
                    {
                        options.map((item, idx) => renderItem(item, idx))
                    }
                </DropdownList>
            </DropdownContainer>
        </Main>
    )
}

const Main = styled.div`
display: flex;
flex-direction: column;
gap: 10px;
width: 100%;
`

const DropdownContainer = styled.div`
width: 100%;
position: relative;
flex-direction: column;
align-items: flex-start;
z-index: 20;
`;

const DropdownList = styled.div`
    position: absolute;
    width: 100%;
    max-height: 200px;
    background-color: #F8F8F8;
    transition: height .3s ease;
    overflow: hidden;
    border-radius: 0 0 10px 10px;
    overflow-y: unset;
    box-shadow: 0 5px 5px rgba(0,0,0,0.2);
    ::-webkit-scrollbar {
    width: 0;
    }
    hr {
        border-top: 1px solid #E5E5E5;
    }
    `;

const OptionItem = styled.div`
    height: 60px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #F8F8F8;
    border: 1px solid #E5E5E5;
    border-top: none;
    padding-right: 20px;
    .example {
        width: 90%;
    }
    ${props => props.isSelected === true && css`
        border: 1px solid #159800;
    `}
    &:hover {
        background-color: #f0f0f0;
    }
`;

const SelectorItem = styled.div`
padding: 0 20px;
cursor: pointer;
display: flex;
align-items: center;
justify-content: space-between;
border: 1px solid #E5E5E5;
background-color: #F8F8F8;
border-radius: 10px 10px 10px 10px;
z-index: 10;
overflow: hidden;
gap: 20px;
.preview {
    height: calc(100% - 40px);
    width: 100%;
}
${props => props.dropDownOpen && css`
    border-radius: 10px 10px 0 0;
`
}
`