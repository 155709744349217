import React, {useRef, useState, useEffect} from 'react'
import secondsToMinutes from '../../utils/toMinutes'
import styled, { css } from 'styled-components'
const Soccer = ({ data, parentContainer, isNotBackgroundVisible }) => {    
    const [initialContainerSize, setInitialContainerSize] = useState({})
  console.log(data)
    const [style, setStyle] = useState({})
     useEffect(() => {
      const design = data.fullDesign
      const propType = {}
      if (!design) return
      switch (design.bandSize) {
        case 'l':
          propType.bandLeft = {height: '100%', width: '20px', marginLeft: design.isBandCorner ? '': '15px'}
          propType.bandRight = {height: '100%', width: '20px', marginRight: design.isBandCorner ? '': '15px'}
          break;
        case 'm':
          propType.bandLeft = {height: '80%', width: '20px', marginLeft: design.isBandCorner ? '': '10px'}
          propType.bandRight = {height: '80%', width: '20px', marginRight: design.isBandCorner ? '': '10px'}
          break;
        case 's':
          propType.bandLeft = {height: '70%', width: '20px', marginLeft: design.isBandCorner ? '': '12px'}
          propType.bandRight = {height: '70%', width: '20px',  marginRight: design.isBandCorner ? '': '12px'}
          break;
        case 'xs':
          propType.bandLeft = {height: '65%', width: '15px', marginLeft: design.isBandCorner ? '': '17px'}
          propType.bandRight = {height: '65%', width: '15px', marginRight: design.isBandCorner ? '': '17px'}
          break
        default:
          break;
      }
      if (design.isRect) {
        propType.angle = 0
      } else propType.angle = 20
      setStyle(propType)
     }, [data])
    const containerRef = useRef();
    const mainRef = useRef()
    useEffect(() => {
        const containerRect = containerRef.current.getBoundingClientRect()
        return setInitialContainerSize({
          height: containerRect.height,
          width: containerRect.width
        })
    }, [containerRef])
    
    const minimumScale = 0.001;
    
    const time = parentContainer ? data.clock : data.clock + data.clock_seconds_diff
    const [isEditing, setIsEditing] = useState(false)
    const [containerStyle, setContainerStyle] = useState({
        translate: `translate(-50%, -50%)`,
        scale: 'scale(1)'
    });

    useEffect(() => {
      const screenWidth = parentContainer ? mainRef.current.parentElement.getBoundingClientRect().width - 70 : mainRef.current.getBoundingClientRect().width - 70;
      const desiredWidthPercentage = 0.3 + (data.full_size / 10) * 0.7; // calculate desired width as a percentage of screen
      const scale = (desiredWidthPercentage * screenWidth) / initialContainerSize.width;
      setContainerStyle({
        ...containerStyle,
        scale: `scale(${scale > minimumScale ? scale : minimumScale})`
      })
    }, [data.full_size, initialContainerSize])
    console.log(containerStyle)
    function useOutsideAlerter(ref) {
      useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
            setIsEditing(false)
          }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          // Unbind the event listener on clean up
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [ref]);
    }
    useOutsideAlerter(containerRef)
    const handleResize = (event, direction) => {
        const screenWidth = parentContainer ? mainRef.current.parentElement.getBoundingClientRect().width - 70 : mainRef.current.getBoundingClientRect().width - 70;
        event.preventDefault();
        event.stopPropagation()
        if (!isEditing) return
        const container = containerRef.current
        if (container) {
          const containerRect = container.getBoundingClientRect();
          const containerWidth = containerRect.width;
          const containerHeight = containerRect.height;
          const handleDrag = (event) => {
            const clientX = event.type === "mousemove" ? event.clientX : event.touches[0].clientX;
            const clientY = event.type === "mousemove" ? event.clientY : event.touches[0].clientY;
            const newWidth = direction.includes("right")
            ? clientX - containerRect.left
            : containerWidth + containerRect.left - clientX;
          const newHeight = direction.includes("bottom")
            ? clientY - containerRect.top
            : containerHeight + containerRect.top - clientY;
              const aspectRatio = initialContainerSize.width / initialContainerSize.height;
                
                if (newWidth / newHeight > aspectRatio) {
                  const calculatedScale = newWidth / initialContainerSize.height;
                  const maxScale = screenWidth / initialContainerSize.width;
                  let newScale = Math.min(calculatedScale, maxScale);
                  if (newScale < minimumScale) {
                    newScale = minimumScale;
                  }
                  setContainerStyle({
                    ...containerStyle,
                    scale: `scale(${newScale})`,
                  });
                } else {
                  const calculatedScale = newHeight / initialContainerSize.height;
                  
                  // Prevent scaling beyond screen width
                  const maxScale = screenWidth / initialContainerSize.width;
                  let newScale = Math.min(calculatedScale, maxScale);
                  if (newScale < minimumScale) {
                    newScale = minimumScale;
                  }
                  setContainerStyle({
                    ...containerStyle,
                    scale: `scale(${newScale})`,
                  });
                }
          }
          const handleDragEnd = () => {
            document.removeEventListener("mousemove", handleDrag);
            document.removeEventListener("touchmove", handleDrag)
            document.removeEventListener("mouseup", handleDragEnd);
            document.removeEventListener("touchend", handleDragEnd)
          };
          document.addEventListener("mousemove", handleDrag);
          document.addEventListener("mouseup", handleDragEnd);
          document.addEventListener("touchmove", handleDrag)
          document.addEventListener("touchend", handleDragEnd)
        }
      };

      const handleDragStart = (event) => {
        event.preventDefault();
        if (!isEditing) return
        const container = containerRef.current
        if (container) {
          const containerRect = container.getBoundingClientRect();
          const offsetX = event.type === "mousedown" ?  event.clientX - containerRect.left: event.touches[0].clientX - containerRect.left;
          const offsetY = event.type === "mousedown" ? event.clientY - containerRect.top :event.touches[0].clientY - containerRect.top ;
          const handleDrag = (event) => {
            event.preventDefault()
            const clientX = event.type === "mousemove" ? event.clientX : event.touches[0].clientX;
            const clientY = event.type === "mousemove" ? event.clientY : event.touches[0].clientY;
            const scale = Number(containerStyle.scale.replace("scale(", "").replace(")", ""))
            const newLeft = clientX - (offsetX / scale);
            const newTop = clientY - (offsetY / scale);
            setContainerStyle({
              ...containerStyle,
              top: 0,
              left: 0,
              translate: `translate(${newLeft}px, ${newTop}px)`
            })
          };
    
          const handleDragEnd = () => {
            document.removeEventListener("mousemove", handleDrag);
            document.removeEventListener("touchmove", handleDrag)
            document.removeEventListener("mouseup", handleDragEnd);
            document.removeEventListener("touchend", handleDragEnd)
          };
    
          document.addEventListener("mousemove", handleDrag);
          document.addEventListener("touchmove", handleDrag, {passive: false})
          document.addEventListener("touchend", handleDragEnd)
          document.addEventListener("mouseup", handleDragEnd);
        }
      };
    return (
        <Main 
        background_disabled={!data.is_showing_results_background}
        ref={mainRef}
        style={parentContainer ? 
          {borderRadius: '10px'}
          : 
          {width: '100vw', height: '100vh'}
        }
        >
          {
            !isNotBackgroundVisible &&
            <img className='background' src={data.background_image?.url || '/assets/results/background.png'} alt=''/>
          }
              <Container
              style={
                {...containerStyle, transform: `${containerStyle.translate} ${containerStyle.scale}` }
              }
              ref={containerRef}
              angle={style.angle}
              design={data.fullDesign}
              onMouseDown={handleDragStart}
              onTouchStart={handleDragStart}
              className={isEditing ? 'editing' : ''}
              onDoubleClick={() => !parentContainer && setIsEditing(!isEditing)}
              >
                <div className='content'>
                  {
                      isEditing &&
                      <>
                      <TopLeftHandle
                      distance={data.fullDesign?.isRect ? 10 : 20}
                      onTouchStart={(e) => handleResize(e, "top-left")} onMouseDown={(e) => handleResize(e, "top-left")} />
                      <TopRightHandle 
                      distance={data.fullDesign?.isRect ? 10 : 20}
                      onTouchStart={(e) => handleResize(e, "top-right")} onMouseDown={(e) => handleResize(e, "top-right")} />
                      <BottomLeftHandle
                      distance={data.fullDesign?.isRect ? 10 : 20}
                      onTouchStart={(e) => handleResize(e, "bottom-left")} onMouseDown={(e) => handleResize(e, "bottom-left")} />
                      <BottomRightHandle
                      distance={data.fullDesign?.isRect ? 10 : 20}
                      onTouchStart={(e) => handleResize(e, "bottom-right")} onMouseDown={(e) => handleResize(e, "bottom-right")} />
                      </>
                  }
                  <div className='upper'>
                    <div className='parallelogram_left'
                    >
                        <div className='band' 
                        style={
                          {...style.bandLeft, background: data.teams?.find(obj => obj.state === 'team1')?.color || 'rgba(0,0,0,0)'}}>
                        </div>
                        <span>
                            {data.teams ? (data.teams.find(obj => obj.state === 'team1').name || 'TEA') : 'TEA'}
                        </span>
                    </div>
                    <div className='results'>
                        <div>
                            <span>
                            {data.teams ? (data.teams.find(obj => obj.state === 'team1').goals || 0) : 0}
                            </span>
                        </div>
                        <span className='separator'></span>
                        <div>
                          <span>
                          {data.teams?.find(obj => obj.state === 'team2')?.goals || 0}
                          </span>
                        </div>
                    </div>
                    <div className='parallelogram_right'>
                        <span>
                            {data.teams?.find(obj => obj.state === 'team2')?.name || 'TEA'}
                        </span>
                        <div className='band' 
                        style={
                          {...style.bandRight, background: data.teams?.find(obj => obj.state === 'team2')?.color || 'rgba(0,0,0,0)'}}></div>
                    </div>
                  </div>
                  <div className='time'>
                    <div className='clock'>
                      <span>
                        {data.time || 1}T
                      </span>
                    </div>
                    <div className='clock'>
                      <span>
                      {data.clock_status === 'running' ? secondsToMinutes(time) : secondsToMinutes(data.clock)}
                      </span>
                    </div>
                  {
                      (data.show_extra_minutes && data.extra_minutes > 0) &&
                      <div className='extra_time'>
                          <span>
                              +{data.extra_minutes}
                          </span>
                      </div>
                  }
                  </div>
                </div>
              </Container>
        </Main>

    )
}

export default Soccer

const Main = styled.div`
overflow: hidden;
position: relative;
height: 100%;
width: 100%;
flex: 1;
> img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
}
${props => props.background_disabled === true && css`
  img {
    opacity: 0;
  }
`}
` 

const Container = styled.div`
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%) scale(1);
width: fit-content;
font-family: 'Poppins';
z-index: 10;
cursor: pointer;
.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

}
&.editing {
    cursor: move;
    ::after {
        content: "";
        position: absolute;
        top: 0px;
        border: 1px solid #30BCED;
        width: 100%;
        left: 0;
        ${props => !props.design?.isRect && css`
            width: calc(100% + 30px);
            left: -15px;
        `}
        height: 100%;
    }
}
.background {
    width: 100vh;
    position: absolute;
    height: 100vw;
    object-fit: cover;
}
.upper {
  display: flex;
  justify-content: center;
}
.parallelogram_left {
    height: 90px;
    min-width: 202px;
    display: flex;
    background-color: #000000;
    transform: skewX(${props => props.angle}deg);
    align-items: center;
    justify-content: center;
    span {
        flex: 1;
        text-align: center;
        display: block;
        transform: skewX(-${props => props.angle}deg);
        color: #FFFFFF;
        z-index: 20;
        text-transform: uppercase;
        font-size: 40px;
    }
}
.parallelogram_right {
    height: 90px;
    min-width: 202px;
    display: flex;
    background-color: #000000;
    transform: skewX(-${props => props.angle}deg);
    align-items: center;
    justify-content: center;
    span {
        flex: 1;
        text-align: center;
        display: block;
        transform: skewX(${props => props.angle}deg);
        color: #FFFFFF;
        z-index: 20;
        text-transform: uppercase;
        font-size: 40px;
    }
}
.results {
    height: 90px;
    display: flex;
    position: relative;
    z-index: -1;
    background-color: #30BCED;
    box-shadow: -50px 0 0 0 #30BCED, 50px 0 0 0 #30BCED;
    .separator {
      display: block;
      padding: 0;
      width: 4px;
      height: 100%;
      background-color: black;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    > div {
        width: 155px;
        &:first-child {
          transform: skewX(${props => props.angle}deg);
          span {
            display: block;
            transform: skewX(-${props => props.angle}deg);
          }
        }
        &:last-child {
          transform: skewX(-${props => props.angle}deg);
          span {
            display: block;
            transform: skewX(${props => props.angle}deg);
          }
        }
        z-index: 10;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 60px;
        font-family: 'Poppins';
    }
}
.time {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 255px;
    ${props => props.design?.isRect && css`
        width: 310px;
        background-color: #FFFFFF;
        justify-content: space-evenly;
    `}
    .clock {
      width: 100%;
      span {
        color: #000000;
        z-index: 20;
        text-transform: uppercase;
        font-size: 40px;
      }
      &:first-child {
        background-color: #FFFFFF;
        transform: skewX(${props => props.angle}deg);
        box-shadow: 10px 0 0 #FFFFFF;
        padding: 0 15px;
        padding-left: 35px;
        text-align: center;
        span {
          display: block;
          transform: skewX(-${props => props.angle}deg);
        }
        ${props => props.design?.isRect && css`
          width: auto;
          text-align: end;
          box-shadow: none;
          padding-left: 15px;
          span {
            width: auto;
          }
          `}
        }
        &:nth-child(2) {
          background-color: #FFFFFF;
          transform: skewX(-${props => props.angle}deg);
          box-shadow: -10px 0 0 #FFFFFF;
          span {
            text-align: center;
            display: block;
            width: 160px;
            transform: skewX(${props => props.angle}deg);
          }
          ${props => props.design?.isRect && css`
            width: auto;
            box-shadow: none;
            span {
              text-align: start;
              width: auto;
            }
        `}
      }
    }

    .extra_time {
        width: 70px;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 100%;
        display: flex;
        align-items: center;
        background: #FF0000;
        transform: skewX(-${props => props.angle}deg);
        span {
            display: block;
            transform: skewX(${props => props.angle}deg);
            color: #FFFFFF;
            z-index: 20;
            text-transform: uppercase;
            font-size: 40px;
            font-weight: 500;
        }
    }
}
`

const ResizeHandle = styled.div`
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: white;
  border: 1px solid #30BCED;
  border-radius: 50%;
  z-index: 100;
`;

const TopLeftHandle = styled(ResizeHandle)`
  top: -10px;
  left: -${props => props.distance}px;
  cursor: nwse-resize;
`;

const TopRightHandle = styled(ResizeHandle)`
  top: -10px;
  right: -${props => props.distance}px;
  cursor: nesw-resize;
`;

const BottomLeftHandle = styled(ResizeHandle)`
  bottom: -10px;
  left: -${props => props.distance}px;
  cursor: nesw-resize;
`;

const BottomRightHandle = styled(ResizeHandle)`
  bottom: -10px;
  right: -${props => props.distance}px;
  cursor: nwse-resize;
`;