import React from 'react'
import styled from 'styled-components'
import { useEffect } from 'react';
import { useGlobal } from '../context/global';
import { useNavigate } from 'react-router-dom'

const Home = () => {
    document.querySelector('html').style.setProperty('--scrollbar-background-thumb-hover', 'transparent');
    document.querySelector('html').style.setProperty('--scrollbar-background-thumb', 'transparent');
    const navigate = useNavigate()
    function nav_sport (sport) {
        navigate(`/sports/${sport}`)
    }
    const {user, setUser, socket} = useGlobal()

    useEffect(() => {
      if (!user) {
        navigate('/')
      }
      if (user) {
        if (user) {
            socket.emit("validate_user", user.token)
        }
      }
    }, []) 
    const logout = () => {
        setUser(null)
        localStorage.removeItem('user')
        navigate('/')
    }
    return (
        <Main>
            <div className="header">
                <h3>Deportes</h3>
                <button onClick={logout}>Cerrar sesion</button>
            </div>
            <div className="sports">
                <div className="sport" onClick={() => nav_sport('soccer')}>
                    <h4>Fútbol</h4>
                    <img src="/assets/sports/football.svg" alt="" />
                </div>
                <div className="sport" onClick={() => nav_sport('basketball')}>
                    <h4>Basketball</h4>
                    <img src="/assets/sports/basketball.svg" alt="" />
                </div>
                <div className="sport" onClick={() => nav_sport('tennis')}>
                    <h4>Tenis</h4>
                    <img src="/assets/sports/tennis.svg" alt="" />
                </div>
                <div className="sport" onClick={() => nav_sport('rugby')}>
                    <h4>Rugby</h4>
                    <img src="/assets/sports/rugby.svg" alt="" />
                </div>
                <div className="sport" onClick={() => nav_sport('handball')}>
                    <h4>Handball</h4>
                    <img src="/assets/sports/handball.svg" alt="" />
                </div>
                <div className="sport" onClick={() => nav_sport('volleyball')}>
                    <h4>Volleyball</h4>
                    <img src="/assets/sports/volleyball.svg" alt="" />
                </div>
                <div className="sport" onClick={() => nav_sport('hockey')}>
                    <h4>Hockey</h4>
                    <img src="/assets/sports/hockey.svg" alt="" />
                </div>
            </div>
            <div className="footer"></div>
        </Main>
    )
}

export default Home

const Main = styled.div`
max-height: 100vh;
height: 100vh;
width: 100%;
background-color: #ffffff;
display: flex;
flex-direction: column;
.header {
    height: 20%;
    min-height: 20%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background-color: #593A9A;
    width: 100%;
    color: #ffffff;
    position: relative;
    button {
        position: absolute;
        right: 15px;
        bottom: 15px;
        color: #FFFFFF;
        background-color: rgba(0,0,0,0);
        border: none;
        cursor: pointer;
    }
    h3 {
        font-size: 17px;
        font-weight: bold;
        margin-bottom: 15px;
        text-transform: uppercase;
    }
}
.sports {
    width: 85%;
    flex: 1;
    background-color: #ffffff;
    display: flex;
    flex-wrap: wrap;
    margin: 40px auto;
    margin-top: 40px;
    align-items:center ;
    max-width: 400px;
    overflow: hidden;
    overflow-y: unset;
    justify-content: space-between;
    .sport {
        width: calc(50% - 10px);
        /* flex: 1; */
        min-width: 120px;
        height: 67px;
        border-radius: 48px;
        cursor: pointer;
        background-color: #593A9A;
        border: 1px solid #8438FF;
        position: relative;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0;
        h4 {
            margin: 0;
            color: #ffffff;
            font-size: 17px;
            font-weight: bold;
            text-transform: uppercase;
        }
        img {
            position: absolute;
        }
    }
}
.footer {
    height: 60px;
    min-height: 60px;
    border-top: 1px solid #B3B3B3;
    background-color: #E5E5E5;
}
`