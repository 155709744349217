import React, {  useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Basketball from './tanter/Basketball'
import Handball from './tanter/Handball'
import Hockey from './tanter/Hockey'
import Rugby from './tanter/Rugby'
import Soccer from './tanter/Soccer'
import Tennis from './tanter/Tennis'
import Volleyball from './tanter/Volleyball'
import { SoccerContext } from '../context/soccer'
import { RugbyContext } from '../context/rugby'
import { BaskeballContext } from '../context/basketball'
import { HandballContext } from '../context/handball'
import { useGlobal } from '../context/global'

const Sport = () => {
    const { sport, user } = useParams()
    const { socket, user: initUser } = useGlobal()
    useEffect(() => {
        if (initUser) {
            socket.emit("validate_user", initUser.token)
        }
        socket.emit("change_sport", sport)
    }, [initUser, socket])

    useEffect(() => {
      window.document.addEventListener('visibilitychange',() => {
          window.location.reload()
      })
    }, [socket, user])
    return (
        <>
            {
                sport === 'soccer' &&
                <SoccerContext>
                    <Soccer />
                </SoccerContext>

            }
            {
                sport === 'basketball' &&
                <BaskeballContext>
                    <Basketball />
                </BaskeballContext>
            }
            {
                sport === 'tennis' &&
                <Tennis />
            }
            {
                sport === 'rugby' &&
                <RugbyContext>
                    <Rugby />
                </RugbyContext>
            }
            {
                sport === 'volleyball' &&
                <Volleyball />
            }
            {
                sport === 'handball' &&
                <HandballContext>
                    <Handball />
                </HandballContext>
            }
            {
                sport === 'hockey' &&
                <Hockey />
            }
        </>
    )
}

export default Sport