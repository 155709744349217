import React from 'react'
import styled from 'styled-components'
import secondsToMinutes from '../../../../utils/toMinutes'
import { useRugby } from '../../../../context/rugby'
import RugbyService from '../../../../services/rugby.service'
import { useGlobal } from '../../../../context/global'
const Clock = () => {
    const { data, setData, intervalClockId, setIntervalClockId, clockStatus, setClockStatus } = useRugby()
    const { socket, user } = useGlobal()
    const time = data.clock ? secondsToMinutes(data.clock) : '00:00'
    const handleTime = (amount) => {
        if ((data.clock + amount) < 0) {
            RugbyService.handleClock({ newTime: 0 }, user.token)
            socket.emit("update_results", { clock: 0 })
            return setData({ ...data, clock: 0 })
        }
        if (data.clock) {
            const new_amount = data.clock + amount > 18000 ? 18000 : data.clock + amount
            RugbyService.handleClock({ newTime: new_amount }, user.token)
            socket.emit("update_results", { clock: new_amount })
            setData({ ...data, clock: new_amount })
        } else {
            setData({ ...data, clock: amount })
            RugbyService.handleClock({ newTime: amount }, user.token)
            socket.emit("update_results", { clock: amount })
        }
    }
    const startClock = () => {
        if (clockStatus === 'running') return
        setClockStatus('running')
        RugbyService.handleClock({ newTime: data.clock || 0, clock_status: 'running' }, user.token)
        socket.emit("update_results", { clock: data.clock || 0, clock_status: 'running' })
        if (!data.clock) setData({ ...data, clock: 0 })

        const clockInterval = setInterval(() => {
            clearInterval(intervalClockId)
            setData(prevData => {
                const new_clock = prevData.clock + 1
                    if (new_clock > 18000) {
                        clearInterval(clockInterval)
                        setClockStatus('stopped')
                        return {...prevData, clock_status: 'stopped'}
                    }
                    else return { ...prevData, clock: new_clock }
            })
        }, 1000)
        setIntervalClockId(clockInterval)
    }
    const resetClock = () => {
        // if (clockStatus === 'reboot') return
        pauseClock()
        setData({ ...data, clock: 0 })
        setClockStatus('reboot')
        RugbyService.handleClock({ newTime: 0, clock_status: 'reboot' }, user.token)
        socket.emit("update_results", { clock: 0, clock_status: 'reboot' })
    }
    const pauseClock = () => {
        // if (clockStatus === 'stopped' || clockStatus === 'reboot') return
        if (clockStatus === 'stopped' || clockStatus === 'reboot') return
        clearInterval(intervalClockId)
        RugbyService.handleClock({ newTime: data.clock || 0, clock_status: 'stopped' }, user.token)
        socket.emit("update_results", { clock: data.clock || 0, clock_status: 'stopped' })
        data.clock && setClockStatus('stopped')
    }
    return (
        <Main>
            <Counter>
                <h4>Reloj</h4>
                <h3>{time}</h3>
                <div className="buttons">
                    <button className={clockStatus === 'running' ? 'start running' : 'start'} onClick={startClock}>Inicio</button>
                    <button className={clockStatus === 'stopped' ? 'stop running' : 'stop'} onClick={pauseClock}>Pausa</button>
                    <button className={clockStatus === 'reboot' ? 'reset active' : 'reset'} onClick={resetClock} >Reset</button>
                </div>
            </Counter>
            <Management>
                <div className="row">
                    <div className="add" onClick={() => handleTime(1)}>
                        +1
                    </div>
                    <div className="add" onClick={() => handleTime(10)}>
                        +10
                    </div>
                    <div className="add" onClick={() => handleTime(60)}>
                        +60
                    </div>
                </div>
                <div className="row">
                    <div className="minus" onClick={() => handleTime(-1)}>
                        -1
                    </div>
                    <div className="minus" onClick={() => handleTime(-10)}>
                        -10
                    </div>
                    <div className="minus" onClick={() => handleTime(-60)}>
                        -60
                    </div>
                </div>
            </Management>
        </Main>
    )
}

export default Clock

const Main = styled.div`
width: calc(90% - 10px);
padding: 20px 5px;
margin: 20px auto;
margin-bottom: 0;
display: flex;
background-color: #F8F8F8;
border: 1px solid #E5E5E5;
max-width: 500px;
border-radius: 10px;
align-items: center;
justify-content: center;
gap: 5px;
> * {
    flex: 1;
    min-width: 0;
    height: 100px;
}
`
const Counter = styled.div`
text-align: center;
white-space: nowrap;
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: center;
h4 {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
}
h3 {
    font-size: 30px;
    font-weight: bolder;
    margin: 0;
    margin-bottom: 10px;
    font-weight: 400;
}
button {
    font-size: 12px;
    margin: 0 2px;
    border-radius: 23px;
    border: 1px solid #159800;
    background-color: white;
    font-weight: 400;
    height: 18px;
    font-family: "Roboto";
    width: 45px;
    height: 22px;
}
button.reset {
    border: 1px solid #E14343;
    background-color: #FFFFFF;
    color: #FFFFFF;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 2px 7px;
    color: #000000;
}
button.active {
    font-weight: 700;
    background-color: #E14343;
    color: #FFFFFF;
}
button.start {
    box-sizing: border-box;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2px 8px;
    gap: 10px;
}
button.running {
    background-color: #159800;
    color: #FFFFFF;
}
button.stop {
    box-sizing: border-box;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2px 5px 2px 6px;
    gap: 10px;
}
`
const Management = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
flex-direction: column;
.row {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    .add, .minus {
        width: 42px;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 1px solid #159800;
        font-size: 14px;
        font-weight: 400;
        cursor: pointer;
        user-select: none;
    }
    .add {
        background-color: #159800;
        color: white;
        margin-bottom: 10px;
    }
    .minus {
        background-color: #FFFFFF;
    }
}
`