import React from 'react'
import styled from 'styled-components'
import Banner from '../../components/tennis/banner'
import Footer from '../../components/tennis/footer'
const Main = styled.div`
position: relative;
width: 100%;
min-height: 100vh;
`
const Tennis = () => {
    return (
        <Main>
            <Banner />
            <Footer />
        </Main>
    )
}

export default Tennis