import http from "./http-common";

export default class SoccerService {
    static handleMatch(data, token) {
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        return http.post('/soccer/create', data, config)
    }
    static handleClock(data, token) {
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        return http.put('/soccer/update/clock', data, config)
    }
    
    static updateBackgroundImage(image, token) {
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        return http.put('/soccer/update/background', {image}, config)
    }
    static finishTable(token) {
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        return http.delete('/soccer/finish', config)
    }
}