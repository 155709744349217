import React from 'react'
import styled from 'styled-components'
import { useSoccer } from '../../../context/soccer'
import Default from './default'
import Footer from './footer'
import BoardSettings from './boardsettings'
import { useLocation, useNavigate } from 'react-router-dom'

const Sidebar = () => {
    const { isSidebarOpen, setIsSidebarOpen, isBoardSettingsOpen, setIsBoardSettingsOpen } = useSoccer()
    isSidebarOpen ?
        document.querySelector('html').style.overflowY = 'hidden'
        :
        document.querySelector('html').style.overflowY = 'overlay'

    const location = useLocation();
    const navigate = useNavigate();

    const addQueryParameter = (param, value) => {
        const queryParams = new URLSearchParams(location.search);
        queryParams.set(param, value);
        navigate({
          pathname: location.pathname,
          search: queryParams.toString(),
        });
    };
    return (
        <Main open={isSidebarOpen}>
            <Banner>
                <img src="/assets/sports/football/banner.svg" alt="" />
                <div className="info">
                    <div className="arrow" onClick={() => {
                        if (isBoardSettingsOpen) {
                            addQueryParameter('isBoardSettingsModalOpen', 'false')
                            setIsBoardSettingsOpen(false)
                            return
                        }
                        addQueryParameter('isSidebarOpen', 'false')
                        setIsSidebarOpen(false)
                        }}>
                        <img src="/assets/common/sidebar/back.svg" alt="" />
                    </div>
                    <h3>Fútbol</h3>
                    <span />
                </div>
            </Banner>
            {
                isBoardSettingsOpen ?
                <BoardSettings/>
                :
                <Default/>
            }
            <Footer />
        </Main>
    )
}

export default Sidebar

const Main = styled.div`
height: 100vh;
width: 100%;
background-color: #FFFFFF;
position: absolute;
top: 0;
left: -100%;
transition: left 0.3s ease;
${props => props.open === true && { left: 0 }}
display: flex;
flex-direction: column;
`
const Banner = styled.div`
background-color: #159800;
width: 100%;
height: 80px;
position: relative;
> img {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    height: 100%;
    width: 90%;
    max-width: 300px;
}
.info {
    width: 95%;
    max-width: 500px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    > * {
        flex: 1;
        margin: 0;
    }
    h3 {
        text-align: center;
        font-size: 17px;
        font-weight: bold;
        color: #ffffff;
        text-transform: uppercase;
        height: fit-content;
    }
    .arrow img {
        cursor: pointer;
    }
   
}
`
