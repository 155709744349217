import React from 'react'
import Times from '../times'
import Pointstable from './points.table'

const Points = () => {
    return (
        <>
            <Times />
            <Pointstable />
        </>
    )
}

export default Points