import React, { useContext, useEffect, useState } from "react"
import io from 'socket.io-client'
const AppProvider = React.createContext()
export const AppContext = ({ children }) => {
    const socket = io(process.env.REACT_APP_WS_URL)
    // const socket = io("ws://localhost:5050")
    const initialUser = JSON.parse(localStorage.getItem('user')) 
    const [user, setUser] = useState(initialUser)
    const values = { socket: socket, user, setUser }
    return <AppProvider.Provider value={values}>{children}</AppProvider.Provider>
}

export const useGlobal = () => {
    return useContext(AppProvider)
}