import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './pages/Home';
import Sport from './pages/Sport';
import Viewer from './pages/Viewer';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Recovery from './pages/Recovery';
import EmailVerified from './pages/EmailVerified';
import RecoveryCheck from './pages/RecoveryCheck';
import ViewerLower from './pages/ViewerLower';
import { AppContext } from './context/global';
import { ViewerContext } from './context/viewer'

function App() {
  
  return (
    <>
      <ViewerContext>
        <BrowserRouter>
          <Routes>
                <Route path='/share/:sport/:userid' element={<Viewer/>} />
                <Route path='/share/:sport/:userid/lower' element={<ViewerLower/>}/>
          </Routes>
        </BrowserRouter>
      </ViewerContext>
      <AppContext>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Login/>}/>
            <Route path='/signup' element={<Signup/>}/>
            <Route path='/recovery' element={<Recovery/>}/>
            <Route path='/recovery/:token' element={<RecoveryCheck/>}/>
            <Route path='/emailverified/:token' element={<EmailVerified/>}/>
            <Route path='/sports' element={<Home />} />
            <Route path='/sports/:sport' element={<Sport />} />
          </Routes>
        </BrowserRouter>
      </AppContext>
    </>
  );
}

export default App;
