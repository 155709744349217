import React from 'react'
import Times from '../times'
import Foulstable from './fouls.table'

const Fouls = () => {
    return (
        <>
            <Times />
            <Foulstable />
        </>
    )
}

export default Fouls