import React from 'react'
import Times from '../times'
import Penaltiestable from './penalties.table'

const Penalties = () => {
    return (
        <>
            <Times />
            <Penaltiestable />
        </>
    )
}

export default Penalties