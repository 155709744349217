import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ShareService from '../services/share.service'
import Basketball from './viewer_lower/Basketball'
import Soccer from './viewer_lower/Soccer'
import { useNavigate } from 'react-router-dom'
import Rugby from './viewer_lower/Rugby'
import { useViewer } from '../context/viewer'

const ViewerLower = () => {
    const { sport, userid } = useParams()
    const [data, setData] = useState({})
    const { socket } = useViewer()
    const navigate = useNavigate()
    const fowardedTimeSports = ['soccer', 'rugby']
    useEffect(() => {
        socket.emit("viewing", userid)
        socket.on("get_sport", (new_sport) => {
            console.log(new_sport)
            navigate(`/share/${new_sport}/${userid}/lower`)
            window.location.reload()
            .catch(error => console.log(error))
        })
        socket.on("get_results", entryData => {
            setData(prevData => {
                const newData = { ...prevData, ...entryData }
                return newData
            })
            const interval_id = window.setInterval(function () { }, Number.MAX_SAFE_INTEGER);
            if (entryData.clock_status === 'running') {
                for (let i = 1; i < interval_id; i++) {
                    window.clearInterval(i);
                }
                setInterval(() => {
                    setData(prevData => {
                        if (fowardedTimeSports.includes(sport)) return { ...prevData, clock: prevData.clock + 1 }
                        else return { ...prevData, clock: prevData.clock - 1 }
                    })
                }, 1000)
            }
            if (entryData.clock_status === 'stopped' || entryData.clock_status === 'reboot') {
                for (let i = 1; i < interval_id; i++) {
                    window.clearInterval(i);
                }
            }
        })

    }, [])
    useEffect(() => {
        ShareService.getMatch(sport, userid)
            .then(response => {
                if (!response.data.content) return
                setData(response.data.content)
                if (response.data.content.clock_status === 'running') {
                    const interval_id = window.setInterval(function () { }, Number.MAX_SAFE_INTEGER);
                    for (let i = 1; i < interval_id; i++) {
                        window.clearInterval(i);
                    }
                    setInterval(() => {
                        setData(prevData => {
                            if (fowardedTimeSports.includes(sport)) return { ...prevData, clock: prevData.clock + 1 }
                            else return { ...prevData, clock: prevData.clock - 1 }
                        })
                    }, 1000)
                }
            })
            .catch(error => console.log(error))
    }, [])
    return (
        <>
            {
                sport === 'soccer' &&
                <Soccer data={data} />
            }
            {
                sport === 'basketball' &&
                <Basketball data={data} />
            }
            {
                sport === 'rugby' &&
                <Rugby data={data} />
            }
        </>
    )
}

export default ViewerLower