import React from 'react'
import { useBasketball } from '../../../context/basketball'
import styled from 'styled-components'
import BasketballService from '../../../services/basketball.service'
import { useGlobal } from '../../../context/global'

const Teams = () => {
    const { setData, data, staticData } = useBasketball()
    const { socket, user } = useGlobal()
    const teamImagesOnChange = (e) => {
        const image = e.target.files[0]
        const name = e.target.name
        if (!image) return
        const reader = new FileReader()
        reader.readAsDataURL(image)
        reader.onloadend = () => {
            let oldItem = data.teams.find(object => object.state === name)
            let newList = data.teams.filter(object => object.state !== name).concat({ ...oldItem, image: reader.result })
            setData({ ...data, teams: newList })
            BasketballService.handleMatch({ teams: newList }, user.token)
            socket.emit("update_results", { teams: newList })
        }
    }
    const onChangeTeams = (e, name) => {
        e.preventDefault()
        const value = e.target.querySelector('h4').innerHTML
        let oldItem = data.teams.find(object => object.state === name)
        let newList = data.teams.filter(object => object.state !== name).concat({ ...oldItem, name: value })
        if (!value.length) return
        setData({ ...data, teams: newList })
        BasketballService.handleMatch({ teams: newList }, user.token)
        socket.emit("update_results", { teams: newList })
    }
    return (
        <Main>
            <Team>
                <div className="title" contentEditable suppressContentEditableWarning
                    onInput={e => onChangeTeams(e, 'team1')}
                    onKeyDown={e => {
                        const string = data.teams?.find(obj => obj.state === 'team1')?.name
                        const valid_keys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight']
                        if (string?.length > 4 && !valid_keys.includes(e.key) ) {
                            e.preventDefault()
                        }
                        if (e.key === "Enter") {
                            e.preventDefault();
                        }
                        if (e.key === "Backspace" || e.key === "Delete" || e.key === "Paste") {
                            const selection = window.getSelection();
                            // Don't allow deleting nodes
                            if (selection.anchorOffset === 0)
                                e.preventDefault();
                        }
                    }}
                >
                    <h4>{staticData.teams.find(object => object.state === 'team1')?.name || 'team 1'}</h4>
                </div>
                <div className="image">
                    <input type="file" name='team1' onChange={teamImagesOnChange} accept=".jpg,.png, .jpeg " />
                    {
                        data.teams.find(object => object.state === 'team1')?.image &&
                        <img src={data.teams.find(object => object.state === 'team1')?.image} alt="" />
                    }
                    {
                        !data.teams.find(object => object.state === 'team1')?.image &&
                        <span>logo equipo</span>
                    }
                </div>
            </Team>
            <Line />
            <Team>
                <div className="title" contentEditable suppressContentEditableWarning
                    onInput={e => onChangeTeams(e, 'team2')}
                    onKeyDown={e => {
                        const string = data.teams?.find(obj => obj.state === 'team2')?.name
                        const valid_keys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight']
                        if (string?.length > 5 && !valid_keys.includes(e.key) ) {
                            e.preventDefault()
                        }
                        if (e.key === "Enter") {
                            e.preventDefault();
                        }
                        if (e.key === "Backspace" || e.key === "Delete" || e.key === "Paste") {
                            const selection = window.getSelection();
                            // Don't allow deleting nodes
                            if (selection.anchorOffset === 0)
                                e.preventDefault();
                        }
                    }}>
                    <h4>{staticData.teams.find(object => object.state === 'team2')?.name || 'team 2'}</h4>
                </div>
                <div className="image">
                    <input type="file" name='team2' onChange={teamImagesOnChange} />
                    {
                        data.teams.find(object => object.state === 'team2')?.image &&
                        <img src={data.teams.find(object => object.state === 'team2')?.image} alt="" />
                    }
                    {
                        !data.teams.find(object => object.state === 'team2')?.image &&
                        <span>logo equipo</span>
                    }
                </div>
            </Team>
        </Main>
    )
}

export default Teams

const Main = styled.div`
display: flex;
justify-content: space-between;
margin: 0 auto;
margin-bottom: 20px;
width: 75%;
max-width: 500px;
min-width: 0;
`
const Team = styled.div`
display: flex;
flex-direction: column;
align-items: center;
margin: 0;
min-width: 0;
justify-content: center;
flex: 1;
.title {
    min-width: 0;
    word-break: break-all;
    white-space: pre-wrap;
    outline: none;
    width: 100%;
    text-align: center;
    h4 {
        margin: 0;
        font-size: 13px;
        color: #282828;
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 10px;
    }
}
.image {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 1px solid #BCBCBC;
    position: relative;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    input {
        position: absolute;
        height: 100%;
        width: 100%;
        opacity: 0;
        cursor: pointer;
    }
    span {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        font-size: 13px;
        text-transform: uppercase;
        color: #BCBCBC;
        z-index: -1;
        user-select: none;
    }
}
`
const Line = styled.span`
display: block;
height: 100%;
width: 1px;
min-width: 1px;
background-color: #BCBCBC;
margin: 0 20px;
`