import http from './http-common'

export default class BasketballService {
    static handleMatch(data, token) {
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        return http.post('/basketball/create', data, config)
    }
    static handleClock(data, token) {
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        return http.put('/basketball/update/clock', data, config)
    }
    static finishTable(token) {
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        return http.delete('/basketball/finish', config)
    }
}