import React from 'react'
import styled from 'styled-components'
import secondsToMinutes from '../../../utils/toMinutes'
import { useSoccer } from '../../../context/soccer'
import SoccerService from '../../../services/soccer.service'
import { useGlobal } from '../../../context/global'
const Times = () => {
    const { data, setData, setIntervalClockId, intervalClockId, clockStatus, setClockStatus } = useSoccer()
    const { socket, user } = useGlobal()
    const time = data.clock ? secondsToMinutes(data.clock) : '00:00'
    const startClock = () => {
        if (clockStatus === 'running') return
        setClockStatus('running')
        SoccerService.handleClock({ newTime: data.clock || 0, clock_status: 'running' }, user.token)
        socket.emit("update_results", { clock: data.clock || 0, clock_status: 'running' })
        if (!data.clock) setData({ ...data, clock: 1 })
        const clockInterval = setInterval(() => {
            setData(prevData => {
                return { ...prevData, clock: prevData.clock + 1 }
            })
        }, 1000)
        setIntervalClockId(clockInterval)
    }
    const resetClock = () => {
        if (clockStatus === 'reboot') return
        pauseClock()
        setData({ ...data, clock: 0 })
        setClockStatus('reboot')
        SoccerService.handleClock({ clock_status: 'reboot', newTime: 0 }, user.token)
        socket.emit("update_results", { clock_status: 'reboot', clock: 0 })

    }
    const pauseClock = () => {
        if (clockStatus === 'stopped' || clockStatus === 'reboot') return
        clearInterval(intervalClockId)
        SoccerService.handleClock({ newTime: data.clock || 0, clock_status: 'stopped' }, user.token)
        socket.emit("update_results", { clock: data.clock || 0, clock_status: 'stopped' })
        data.clock && setClockStatus('stopped')
    }
    const addExtraMinute = () => {
        setData({ ...data, extra_minutes: data.extra_minutes + 1 || 1 })
        SoccerService.handleMatch({ extra_minutes: data.extra_minutes + 1 || 1 }, user.token)
        socket.emit("update_results", { extra_minutes: data.extra_minutes + 1 || 1 })
    }
    const substractExtraMinute = () => {
        if (!data.extra_minutes) return
        setData({ ...data, extra_minutes: data.extra_minutes - 1 })
        SoccerService.handleMatch({ extra_minutes: data.extra_minutes - 1 }, user.token)
        socket.emit("update_results", { extra_minutes: data.extra_minutes - 1 })
    }
    const showExtraMinutes = () => {
        setData({ ...data, show_extra_minutes: !data.show_extra_minutes })
        SoccerService.handleMatch({ show_extra_minutes: !data.show_extra_minutes }, user.token)
        socket.emit("update_results", { show_extra_minutes: !data.show_extra_minutes })
    }
    return (
        <Main>
            <Counter>
                <h4>{data.time || 1}T</h4>
                <h3>{time}</h3>
                <button className={clockStatus === 'running' ? 'start running' : 'start'} onClick={startClock}>Inicio</button>
                <button className={clockStatus === 'stopped' ? 'stop running' : 'stop'} onClick={pauseClock}>Pausa</button>
                <button className={clockStatus === 'reboot' ? 'reset active' : 'reset'} onClick={resetClock}>Reset</button>
            </Counter>
            <Controller>
                <h4>Minutos Extra</h4>
                <div className="info">
                    <div className='minus' onClick={substractExtraMinute}>
                        <span>
                            -
                        </span>
                    </div>
                    <span>{data.extra_minutes || 0}</span>
                    <div className='plus' onClick={addExtraMinute}>
                        <span>
                            +
                        </span>
                    </div>
                </div>
                <button onClick={showExtraMinutes} className={data.show_extra_minutes ? 'active' : ''}>Mostrar tiempo extra</button>
            </Controller>
        </Main>
    )
}

export default Times

const Main = styled.div`
width: calc(90% - 20px);
padding: 15px 10px;
margin: 0 auto;
display: flex;
background-color: #F8F8F8;
border: 1px solid #E5E5E5;
max-width: 500px;
border-radius: 10px;
align-items: center;
justify-content: center;
> * {
    flex: 1;
    min-width: 0;
}
`
const Counter = styled.div`
text-align: center;
white-space: nowrap;
h4, h3 {
    margin: 0;
    font-weight: 400;
}
h4 {
    font-size: 20px;
}
h3 {
    font-size: 30px;
    margin-top: 10px;
}
button {
    margin: 3px;
    border-radius: 23px;
    font-size: 12px;
    width: 45px;
height: 22px;
display: inline-flex;
flex-direction: column;
justify-content: center;
align-items: center;
font-family: "Roboto";
margin-top: 15px;
}
button.start {
    background-color: #FFFFFF;
    border: 1px solid #159800;
    color: #000000;
    padding: 2px 8px;
gap: 10px;
}
button.stop {
    border: 1px solid #159800;
    background-color: #FFFFFF;
    padding: 2px 5px 2px 6px;
gap: 10px;
}
button.running {
    background-color: #159800;
    color: #FFFFFF;
}
button.reset {
    border: 1px solid #E14343;
    background: #FFFFFF;
    color: #FFFFFF;
    padding: 2px 7px;
    gap: 10px;
    color: #000000;
}
button.active {
    font-weight: 700;
    background-color: #E14343;
    color: #FFFFFF;
}
`
const Controller = styled.div`
text-align: center;
h4 {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
}
.info {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px auto;
    gap: 10px;
    div {
        width: 30px;
        height: 30px;
        border: 1px solid #159800;
        border-radius: 50%;
        position: relative;
        cursor: pointer;
        user-select: none;
        span {
            position: absolute;
            left: 50%;
            top: 50%;
            font-weight: 700;
            font-size: 20px;
        }
    }
    .minus {
        background-color: #FFFFFF;
        span {
            transform: translate(-50%, -50%);
        }
    }
    .plus {
        background-color: #159800;
        color: #FFFFFF;
        span {
            transform: translate(-50% , -50%);
        }
    }
    > span {
        font-size: 30px;
        font-weight: 400;
    }
}
button {
    padding: 5px 10px;
    border-radius: 23px;
    border: 1px solid #159800;
    background-color: #FFFFFF;
    cursor: pointer;
    font-size: 9px;
}
button.active {
    background-color: #159800;
    color: #FFFFFF;
}
`