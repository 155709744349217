import { useMemo } from "react";
import { useState, useRef, useEffect } from "react";
import styled from "styled-components";

function Circle({x, setX , setSize, onChangeSize}) {
    const [circleStyle, setCircleStyle] = useState({
    })
    useEffect(() => {
      if (x !== undefined) return setCircleStyle({left: x})
    }, [x])
    const containerRef = useRef()
  
    const handleDragStart = (event) => {
      event.preventDefault();
      const container = containerRef.current
      if (container) {
        const containerRect = container.getBoundingClientRect();
        const parentContainerRect = container.parentElement?.getBoundingClientRect()
        if (!containerRect || !parentContainerRect) return
        const currentLeft = x || 0;
        const offsetX = event.type === "mousedown" ?  event.clientX - containerRect.left - currentLeft: event.touches[0].clientX - containerRect.left - currentLeft;
        const handleDrag = (event) => {
          const clientX = event.type === "mousemove" ? event.clientX : event.touches[0].clientX;
          const newLeft = clientX - containerRect.left - offsetX
          const maxLeft = parentContainerRect.width - (containerRect.width / 2)

          const stepSize = maxLeft / 10;
          const closestStep = Math.round(newLeft / stepSize) * stepSize;
          const size = (closestStep / maxLeft) * 10;
          const boundedSize = Math.min(10, Math.max(0, size));
          setSize(prevSize => {
            if (prevSize.toFixed() === size.toFixed()) return prevSize;
            else {
              if (size >= 0 && size <= 10) {
                onChangeSize(size);
                return size;
              } else { return prevSize}
            }
          });
          
          
          setCircleStyle({
            ...circleStyle,
            left: (boundedSize / 10) * maxLeft,
          });
      
          setX((boundedSize / 10) * maxLeft);
        };
  
        const handleDragEnd = () => {
          document.removeEventListener("mousemove", handleDrag);
          document.removeEventListener("touchmove", handleDrag)
          document.removeEventListener("mouseup", handleDragEnd);
          document.removeEventListener("touchend", handleDragEnd)
        };
  
        document.addEventListener("mousemove", handleDrag);
        document.addEventListener("touchmove", handleDrag, {passive: false})
        document.addEventListener("touchend", handleDragEnd)
        document.addEventListener("mouseup", handleDragEnd);
      }
    };
  
    return (
      <div
        ref={containerRef}
        className="circle"
        style={circleStyle}
        onMouseDown={handleDragStart}
        onTouchStart={handleDragStart}
      />
    );
  }

  export default function Scale({size, setSize, onChangeSize}) {
    const [leftX, setLeftX] = useState(0)
    const bar = useRef(null);
    useEffect(() => {
      const parentElement = bar.current.parentElement
      const parentContainerRect = parentElement?.getBoundingClientRect()
      setLeftX(parentContainerRect.width * (size / 10))
    }, [size])
  
    useEffect(() => {
      const handleResize = () => {
        const parentElement = bar.current.parentElement
        const parentContainerRect = parentElement?.getBoundingClientRect()
        // if (!size) setLeftX(barRect.width / 2)
        setLeftX(parentContainerRect.width * (size / 10))
      }
      // Update left position on window resize
      window.addEventListener('resize', handleResize);

      // Cleanup the event listener when the component unmounts
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, [size])


    const sizes = useMemo(() => {
      return [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10
      ]
    }, [])

    return (
      <Main>
        <h2>Escala</h2>
        <div className="scale">
          <Circle
          x={leftX}
          setX={setLeftX}
          setSize={setSize}
          currentSize={size}
          onChangeSize={onChangeSize}
          />
          <div className="blank_bar" ref={bar}>
            <div className="sizes">
              {
                sizes.map((value, idx) => {
                  return <span 
                  key={idx}
                  style={{opacity: `${value.toFixed() === size.toFixed() ? 0 : 1}`}}>{value}</span>
                })
              }
            </div>
          </div>
          <div className="bar" style={{ width: `${leftX}px` }} />
          <span style={{left: leftX}}>{size.toFixed()}</span>
        </div>
      </Main>
    );
  }

const Main = styled.div`
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #E5E5E5;
  background: #F8F8F8;
  h2 {
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: 400;
    text-align: center;
  }
  .scale {
    position: relative;
    width: 100%;
    > span {
      position: absolute;
      white-space: nowrap;
      font-size: 12px;
      top: calc(50% + 21px);
      left: 50%;
      transform: translate(-50%, -50%);
      color: #159800;
    }
    height: 40px;
  }
  .circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #FFFFFF;
    outline: 1px solid #C1C9D2;
    cursor: pointer;
    box-shadow: 0px 5px 3px rgba(0,0,0,0.1);
    z-index: 10;
  }

  .bar {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 4px;
    background-color: #159800;
  }
  .blank_bar {
    height: 4px;
    width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #C1C9D2;
    border-radius: 10px;
    .sizes {
      position: absolute;
      top: 15px;
      display: flex;
      width: 100%;
      height: 18px;
      justify-content: space-between;
      font-size: 12px;
    }
  }
`