import React from 'react'
import styled from 'styled-components'
import { useGlobal } from '../../context/global'
import { useRugby } from '../../context/rugby'
import RugbyService from '../../services/rugby.service'
const Banner = () => {
    const { setIsSidebarOpen, setData, setClockStatus, initialState } = useRugby()
    const { socket, user } = useGlobal()
    return (
        <Main>
            <img src="/assets/sports/rugby/banner.svg" alt="" />
            <div className="info">
                <div>
                    <div className="ham" onClick={() => setIsSidebarOpen(true)}>
                        <span></span>
                    </div>
                </div>
                <h3>Rugby</h3>
                <h4 onClick={() => {
                    setData(initialState)
                    setClockStatus('reboot')
                    RugbyService.handleMatch({ ...initialState, clock: 0, clock_status: 'reboot', time: 0 }, user.token)
                    socket.emit("update_results", { ...initialState, clock: 0, clock_status: 'reboot', time: 0 })
                }}>Reset Tablero</h4>
            </div>
        </Main>
    )
}

export default Banner

const Main = styled.div`
background-color: #159800;
width: 100%;
height: 60px;
min-height: 60px;
position: relative;
img {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    width: 90%;
    max-width: 300px;
    user-select: none;
}
.info {
    width: 90%;
    max-width: 500px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    > * {
        flex: 1;
        margin: 0;
    }
    h3 {
        text-align: center;
        font-size: 17px;
        font-weight: bold;
        color: #ffffff;
        text-transform: uppercase ;
    }
    h4 {
        font-size: 12px;
        font-weight: 400;
        color: #ffffff;
        text-align: end;
        cursor: pointer;
        user-select: none;
    }
    .ham {
        cursor: pointer;
        height: 26px;
        width: 30px;
        span {
            display: block;
            height: 3px;
            border-radius: 7px;
            width: 30px;
            background-color: #ffffff;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }
        span::after, span::before {
            content: '';
            height: 3px;
            border-radius: 7px;
            width: 30px;
            background-color: #ffffff;
            position: absolute;
            left: 0;
        }
        span::after {
            top: 8px;
        }
        span::before {
            top: -8px;
        }
    }
}
`