import styled from "styled-components";
import { useNavigate } from "react-router-dom"
import UsersService from "../services/users.service";
import { useState } from "react";

export default function Signup() {
    const navigate = useNavigate()
    const [submitData, setSubmitData] = useState({email: '', password: ''})
    const [isMailSent, setIsMailSent] = useState(false)
    async function handleSubmitData(e, data) {
        e.preventDefault()
        const response = await UsersService.signup(data)
        console.log(response)
        setIsMailSent(true)
    }
    function onChangeData (e) { 
        const name = e.target.name
        const value = e.target.value
        setSubmitData({...submitData, [name]: value})
    }
    return (
        <Main>
            <AppBar>
                {
                    !isMailSent &&
                    <>
                        <h2>REGISTRO</h2>
                        <img 
                            src={"/assets/common/left-arrow.svg"}
                            onClick={() => navigate('/')}
                            alt=""
                        />
                    </>
                }
            </AppBar>
            {
                !isMailSent 
                ?
                <Form onSubmit={(e) => handleSubmitData(e, submitData)}>
                    <img src="/assets/aiomix.svg" alt=""/>
                    <div className="form">
                        <div className="inputGroup">
                            <input type="text" 
                            required={true}
                            name="email"
                            value={submitData.email}
                            onChange={onChangeData}
                            />
                            <label>Email</label>
                        </div>
                        <div className="inputGroup">
                            <input type="text" required={true}
                            name="password"
                            value={submitData.password}
                            onChange={onChangeData}
                            />
                            <label>Password</label>
                        </div>
                    </div>
                    <button type="submit" className="btn">Registrarse</button>
                </Form>
            :
                <MailSent>
                    <span>Se ha enviado un email de
                    verificación a su cuenta</span>
                    <button type="submit" className="btn"
                    onClick={() => navigate('/')}
                    >Go Login</button>
                </MailSent>
            }
            
        </Main>
    )
}

const Main = styled.section`
background-color: #FFF;
max-height: 100vh;
height: 100vh;
`

const AppBar = styled.div`
height: 15%;
background-color: #000000;
position: relative;
h2 {
    position: absolute;
    font-size: '17px';
    font-weight: 500;
    bottom: 20%;
    left: 50%;
    transform: translateX(-50%);
    color: #FFFFFF;
}
img {
    position: absolute;
    bottom: 20%;
    left: 20px;
    cursor: pointer;
}
`

const Form = styled.form`
width: 100%;
height: 85%;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
gap: 5%;
img {
    margin-bottom: 15%;
}
.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.inputGroup {
    position: relative;
    width: 270px;
    :first-child {
        margin-bottom: 30px;
    }
}
.inputGroup input {
    font-size: 100%;
    padding: 0.8em 1.3em;
    outline: none;
    border: 2px solid rgb(200, 200, 200);
    background-color: transparent;
    border-radius: 20px;
    width: calc(100% - 2.6em);
}

.inputGroup label {
    font-size: 100%;
    position: absolute;
    left: 0;
    margin-left: calc(1.3em - 10px);
    pointer-events: none;
    transition: all 0.3s ease;
    top: 50%;
    transform: translateY(-50%);
    color: rgb(100, 100, 100);
    background-color: #FFFFFF;
    padding: 0 10px;
}

.btn {
    text-transform: uppercase;
    text-decoration: none;
    padding: 10px 30px;
    border: 1px solid;
    background-color: #ccc;
    border-radius: 1000px;
    display: inline-block;
    transition: all .2s;
    position: relative;
    cursor: pointer;
}

.btn:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(27, 27, 27, .5);
}

.btn::after {
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 100px;
    top: 0;
    left: 0;
    position: absolute;
    z-index: -1;
    transition: all .3s;
    opacity: 0.5;
}

.btn:hover::after {
    background-color: rgb(100, 100, 100);
    transform: scaleX(1.4) scaleY(1.5);
    opacity: 0;
}

.inputGroup :is(input:focus, input:valid)~label {
    top: -10px;
    transform: scale(.9);
}

.inputGroup :is(input:focus, input:valid) {
    border-color: #593A9A;
}
`

const MailSent = styled.div`
span {
    max-width: 70%;
    text-align: center;
    color: #818181;

}
width: 100%;
height: 85%;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
gap: 10%;

.btn {
    text-transform: uppercase;
    text-decoration: none;
    padding: 10px 30px;
    border: 1px solid;
    background-color: #ccc;
    border-radius: 1000px;
    display: inline-block;
    transition: all .2s;
    position: relative;
    cursor: pointer;
}

.btn:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(27, 27, 27, .5);
}

.btn::after {
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 100px;
    top: 0;
    left: 0;
    position: absolute;
    z-index: -1;
    transition: all .3s;
    opacity: 0.5;
}

.btn:hover::after {
    background-color: rgb(100, 100, 100);
    transform: scaleX(1.4) scaleY(1.5);
    opacity: 0;
}
`