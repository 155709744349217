import styled, { css } from "styled-components";
import {useSoccer} from '../../../context/soccer'
import InputDropdown from "../common/InputDropdown";
import Scale from "../common/Scale";
import { useEffect, useState } from "react";
import SoccerService from "../../../services/soccer.service";
import { useGlobal } from "../../../context/global";
import { useParams } from "react-router-dom";
import deepCompare from '../../../utils/deepCompare'
import heic2any from 'heic2any'


function Lower () {
    const {user, socket} = useGlobal()
    const {data, setData} = useSoccer()
    const {sport} = useParams()
    const [size, setSize] = useState(data.lower_size || 5)
    useEffect(() => {
        data.lower_size !== undefined && setSize(data.lower_size)
    }, [data.lower_size])
    const designOptions = [
        {
            example: "/assets/lower_thirds/first.svg",
            bandSize: 'l',
            isRect: false,
            isBandCorner: true
        },
        {
            example: "/assets/lower_thirds/second.svg",
            bandSize: 'm',
            isRect: false,
            isBandCorner: false,
        },
        {
            example: "/assets/lower_thirds/third.svg",
            bandSize: 's',
            isRect: false,
            isBandCorner: false,
        },
        {
            example: "/assets/lower_thirds/fourth.svg",
            bandSize: 'l',
            isRect: true,
            isBandCorner: true,
        },
        {
            example: "/assets/lower_thirds/first_rect.svg",
            bandSize: 'l',
            isRect: true,
            isBandCorner: false,
        },
        {
            example: "/assets/lower_thirds/second_rect.svg",
            bandSize: 'm',
            isRect: true,
            isBandCorner: false,
        },
        {
            example: "/assets/lower_thirds/third_rect.svg",
            bandSize: 's',
            isRect: true,
            isBandCorner: false,
        },
        {
            example: "/assets/lower_thirds/fourth_rect.svg",
            bandSize: 'xs',
            isRect: true,
            isBandCorner: false,
        },
    ]

    const [design, setDesign] = useState(data.lowerDesign || designOptions[0])
    async function onChangeSize (newSize) {
        try {
            setData({...data, lower_size: newSize})
            await SoccerService.handleMatch({lower_size: newSize}, user.token)
            await socket.emit("update_results", {lower_size: newSize })
        } catch (error) {
            console.log(error)
        }
    }
    const positionOptions = [
        {
            top: '10px',
            left: '10px',
            bottom: null,
            right: null,
            translate: 'translate(0, 0)',
            transformOrigin: 'top left'
        },
        {
            top: '10px',
            right: '10px',
            left: null,
            bottom: null,
            translate: null,
            transformOrigin: 'top right'
        },
        {
            top: '10px',
            left: '50%',
            translate: 'translate(-50%, 0)',
            bottom: null,
            right: null,
            transformOrigin: 'top center'
        },
        {
            bottom: '10px',
            left: '10px',
            top: null,
            right: null,
            translate: 'translate(0, 0)',
            transformOrigin: 'bottom left'
        }, 
        {
            bottom: '10px',
            left: '50%',
            translate: 'translate(-50%, 0)',
            top: null,
            right: null,
            transformOrigin: 'bottom center',
        },
        {
            bottom: '10px',
            right: '10px',
            top: null,
            left: null,
            translate: 'translate(0, 0)',
            transformOrigin: 'bottom right'
        }
    ]
    async function onChangePosition (position) {
        try {
            setData({...data, lowerPosition: position})
            await SoccerService.handleMatch({lowerPosition: position}, user.token)
            await socket.emit("update_results", {lowerPosition: position })
        } catch (error) {
            console.log(error)
        }
    }

    async function onChangeTeamColor(e, team_state) {
        const color = e.target.value
        let oldItem = data.teams.find(object => object.state === team_state)
        let newList = data.teams.filter(object => object.state !== team_state).concat({ ...oldItem, color })
        if (!color) return
        setData({ ...data, teams: newList })
    }

    async function onSubmitTeamColor(e, team_state) {
        const color = e.target.value
        let oldItem = data.teams.find(object => object.state === team_state)
        let newList = data.teams.filter(object => object.state !== team_state).concat({ ...oldItem, color })
        if (!color) return
        setData({ ...data, teams: newList })
        await SoccerService.handleMatch({teams: newList}, user.token)
        await socket.emit("update_results", { teams: newList })
    }

    async function onChangeSelectedDesign(newOption) {
        try {
            setDesign(newOption)
            setData(prevData => ({...prevData, lowerDesign: newOption}))
            await SoccerService.handleMatch({lowerDesign: newOption}, user.token)
            await socket.emit('update_results', {lowerDesign: newOption})
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <LowerMain>
            <h2>Previsualizacion</h2>
            <InputDropdown
            selectedOptionHeight={60}
            options={designOptions}
            defaultOptionHeight={60}
            selectedOption={design}
            onChangeSelectedOption={onChangeSelectedDesign}
            designType={'lowerDesign'}
            type={'lower'}
            />
            <Position>
                <div className="color">
                    <h3>Color</h3>
                    <div className="team">
                        <h5>{data.teams.find(object => object.state === 'team1')?.name || 'team 1'}</h5>
                        <div className="input-color" style={{background: data.teams.find(object => object.state === 'team1')?.color || ''}}>
                            <input type="color" 
                            value={data.teams.find(object => object.state === 'team1')?.color || ''} 
                            onChange={e => onChangeTeamColor(e, 'team1')}
                            onBlur={e => onSubmitTeamColor(e, 'team1')}
                            />
                        </div>
                    </div>
                    <div className="team">
                        <h5>{data.teams.find(object => object.state === 'team2')?.name || 'team 2'}</h5>
                        <div style={{background: data.teams.find(object => object.state === 'team2')?.color || ''}} className="input-color">
                            <input type="color" 
                            value={data.teams.find(object => object.state === 'team2')?.color || ''} 
                            onChange={e => onChangeTeamColor(e, 'team2')}
                            onBlur={e => onSubmitTeamColor(e, 'team2')}
                            />
                        </div>
                    </div>
                </div>
                <div className="position">
                    <h3>Position</h3>
                    <div className="display">
                        {
                            positionOptions.map((style, idx) => {
                                return <OptionDisplay style={{...style, transform: style.translate}} onClick={() => onChangePosition(style)} selected={deepCompare(style, data.lowerPosition)}  key={idx}/>
                            })
                        }
                    </div>
                </div>
            </Position>
                <Scale
                size={size}
                setSize={setSize}
                onChangeSize={onChangeSize}
                />
            <div className="link">
                <h3>URL Lower Third (OBS, vMix...)</h3>
                <div>
                    <div className="url">{window.location.origin}/share/{sport}/{user.user?._id}/lower</div>
                    <button
                    onClick={() => {
                        const str = `${window.location.origin}/share/${sport}/${user.user?._id}/lower`
                        navigator.clipboard.writeText(str)
                        .then(() => {
                            alert('Copied to clipboard')
                        })
                    }}
                    >
                        <img
                        title="Copy"
                        alt="" src="/assets/common/copy.svg"/>
                    </button>
                </div>
            </div>
        </LowerMain>
    )
}

function Full () {
    const {user, socket} = useGlobal()
    const {data, setData} = useSoccer()
    const {sport} = useParams()

    const designOptions = [
        {
            example: "/assets/full_screen/first.svg",
            bandSize: 'l',
            isRect: false,
            isBandCorner: true,
        },
        {
            example: "/assets/full_screen/second.svg",
            bandSize: 'm',
            isRect: false,
            isBandCorner: false,
        },
        {
            example: "/assets/full_screen/third.svg",
            bandSize: 's',
            isRect: false,
            isBandCorner: false,
        },
        {
            example: "/assets/full_screen/first_rect.svg",
            bandSize: 'l',
            isRect: true,
            isBandCorner: true,
        },
        {
            example: "/assets/full_screen/second_rect.svg",
            bandSize: 'l',
            isRect: true,
            isBandCorner: false,
        },
        {
            example: "/assets/full_screen/third_rect.svg",
            bandSize: 'm',
            isRect: true,
            isBandCorner: false,
        },
        {
            example: "/assets/full_screen/fourth_rect.svg",
            bandSize: 's',
            isRect: true,
            isBandCorner: false,
        },
        {
            example: "/assets/full_screen/fifth_rect.svg",
            bandSize: 'xs',
            isRect: true,
            isBandCorner: false,
        },
    ]

    const [design, setDesign] = useState(data.fullDesign || designOptions[0])
    const [size, setSize] = useState(data.full_size || 5)
    useEffect(() => {
        data.fullDesign !== undefined && setDesign(data.fullDesign)
        data.full_size !== undefined && setSize(data.full_size)
    }, [data.full_size, data.fullDesign])
    async function onChangeSelectedDesign(newOption) {
        try {
            setDesign(newOption)
            setData(prevData => ({...prevData, fullDesign: newOption}))
            await SoccerService.handleMatch({fullDesign: newOption}, user.token)
            await socket.emit('update_results', {fullDesign: newOption})
        } catch (error) {
            console.log(error)
        }
    }

    async function onChangeSize (newSize) {
        try {
            console.log(newSize)
            setData({...data, full_size: newSize})
            await SoccerService.handleMatch({full_size: newSize}, user.token)
            await socket.emit("update_results", {full_size: newSize })
        } catch (error) {
            console.log(error)
        }
    }

    function onChangeBackground(e) {
        const image = e.target.files[0];
        if (!image) return;
        if (image.type === 'image/heic') {
            heic2any({
                blob: image,
                toType: 'image/jpeg',
                quality: 0.5 // You can adjust the quality
            }).then((jpegBlob) => {
                const jpegFile = new File([jpegBlob], "image.jpg", { type: "image/jpeg" });
                const reader = new FileReader();
                reader.readAsDataURL(jpegFile);
                reader.onloadend = async () => {
                    try {
                        console.log('Calling updateBackgroundImage...');
                        const imageData = (await SoccerService.updateBackgroundImage(reader.result?.toString(), user.token)).data;
                        console.log('Update background image response:', imageData);
                        setData({ ...data, background_image: imageData });
                        // update to all
                        socket.emit("update_results", { background_image: imageData })
                    } catch (error) {
                        console.error('Error updating background image:', error);
                    }
                };
            });
        } else {
            const reader = new FileReader();
            reader.onerror = (error) => {
                console.error('Error reading file:', error);
            };
            reader.readAsDataURL(image);
            reader.onloadend = async () => {
                try {
                    console.log('Calling updateBackgroundImage...');
                    const imageData = (await SoccerService.updateBackgroundImage(reader.result, user.token)).data;
                    console.log('Update background image response:', imageData);
                    setData({ ...data, background_image: imageData });
                    // update to all
                    socket.emit("update_results", { background_image: imageData })
                } catch (error) {
                    console.error('Error updating background image:', error);
                }
            };
        }
    }

    return (
        <FullMain>
            <h2>Previsualizacion</h2>
            <InputDropdown
            options={designOptions}
            selectedOption={design}
            onChangeSelectedOption={onChangeSelectedDesign}
            selectedOptionHeight={220}
            defaultOptionHeight={130}
            designType={'fullDesign'}
            type={'full'}
            />
            <div className="background">
                <h4>Seleccionar Fondo</h4>
                <div className="input">
                    <img className="preview" src={data.background_image?.url || "/assets/results/background.png"} alt=""/>
                    <input onChange={onChangeBackground} type="file" name="background_image" accept=".jpg, .jpeg, .png, .heic"/>
                </div>
                <div className="eyeball"
                onClick={() => {
                    SoccerService.handleMatch({is_showing_results_background: !data.is_showing_results_background}, user.token)
                    socket.emit("update_results", {is_showing_results_background: !data.is_showing_results_background})
                    const new_data = {...data, is_showing_results_background: !data.is_showing_results_background}
                    setData(new_data)
                }}
                >
                    <img src={`/assets/common/${data.is_showing_results_background ? 'eyeball' : 'eyeball-closed'}.svg`} alt="" />
                </div>
            </div>
            <Scale
            size={size}
            setSize={setSize}
            onChangeSize={onChangeSize}
            />
            <div className="link">
                <h3>URL Full Screen (OBS, vMix...)</h3>
                <div>
                    <div className="url">{window.location.origin}/share/{sport}/{user.user?._id}</div>
                    <button
                        onClick={() => {
                            const str = `${window.location.origin}/share/${sport}/${user.user?._id}`
                            navigator.clipboard.writeText(str)
                            .then(() => {
                                alert('Copied to clipboard')
                            })
                        }}
                    >
                    <img 
                    title="Copy"
                    alt="" src="/assets/common/copy.svg"/>
                    </button>
                </div>
            </div>
        </FullMain>
    )
}

export default function BoardSettings() {
    const {boardTab} = useSoccer()
    return (
        <Main>
            {
                boardTab === 'lower' &&
                <Lower/>
            }
            {
                boardTab === 'full' &&
                <Full/>
            }
        </Main>
    )
}

const Main = styled.div`
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
gap: 25px;
flex: 1;
width: 90%;
max-width: 500px;
margin: auto;
`

const LowerMain = styled.div`
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
justify-content: space-evenly;
h2 {
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: 400;
    text-align: center;
}

.link {
    text-align: center;
    background-color: #F8F8F8;
    border-radius: 10px;
    border: 1px solid #E5E5E5;
    margin-top: 10px;
    padding: 15px;
    button {
        background-color: transparent;
        border: none;
    }
    h3 {
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 400;
    }
    > div {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        > div {
            flex: 1;
            padding: 4px 8px;
            background-color: #FFFFFF;
            border: 1px solid #CFD0E2;
            color: #6C6F93;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            border-radius: 4px;
            font-size: 13px;
        }
        img {
            cursor: pointer;
        }
    }
}
`

const FullMain = styled.div`
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
justify-content: space-evenly;
> h2 {
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: 400;
    text-align: center;
}
> .background {
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #E5E5E5;
    background: #F8F8F8;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    h4 {
        font-size: 14px;
        font-weight: 400;
    }
    .input {
        height: 31px;
        width: 55px;
        border-radius: 5px;
        overflow: hidden;
        position: relative;
        .preview {
            cursor: pointer;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
        input {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            opacity: 0;
            cursor: pointer;
        }
    }
    .eyeball {
        width: 27px;
        height: 27px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
    }
}

.link {
    text-align: center;
    background-color: #F8F8F8;
    border-radius: 10px;
    border: 1px solid #E5E5E5;
    margin-top: 10px;
    padding: 15px;
    button {
        background-color: transparent;
        border: none;
    }
    h3 {
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 400;
    }
    > div {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        > div {
            flex: 1;
            padding: 4px 8px;
            background-color: #FFFFFF;
            border: 1px solid #CFD0E2;
            color: #6C6F93;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            border-radius: 4px;
            font-size: 13px;
        }
        img {
            cursor: pointer;
        }
    }
}
`

const Position = styled.div`
width: calc(100% - 30px);
padding: 15px;
border-radius: 10px;
border: 1px solid #E5E5E5;
background: #F8F8F8;
display: flex;
gap: 20px;
.color {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    h3 {
        font-size: 14px;
        font-weight: 400;
    }
    .team {
        display: flex;
        align-items: center;
        gap: 10px;
        h5 {
            font-size: 11px;
            font-weight: 400;
        }
        .input-color {
            width: 25px;
            height: 25px;
            border: 1px solid #C1C9D2;
            border-radius: 50%;
            position: relative;
            input {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                opacity: 0;
            }
        }
    }
}
.position {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 15px;
    h3 {
        font-size: 14px;
        font-weight: 400;
    }
    .display {
        height: 100%;
        width: 100%;
        position: relative;
        border-radius: 10px;
        border: 1px solid #C1C9D2;
    }
}
`

const OptionDisplay = styled.div`
width: 20px;
height: 20px;
border-radius: 50%;
border: 1px solid #C1C9D2;
background: #FFF;
position: absolute;
cursor: pointer;
${props => props.selected && css`
    outline: 1px solid #159800;
    &::after {
        width: 15px;
        height: 15px;
        background-color: #159800;
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
    }
`}
`