import http from "./http-common";

export default class UsersService {
    static signup(data) {
        return http.post('/users/signup', data)
    }

    static login(data) {
        return http.post('/users/login', data)
    }

    static validate_email(token_id) {
        return http.post('/users/validate_email', {token_id})
    }
    
    static check_recovery_token(token_id) {
        return http.post('/users/check_recovery_token', {token_id})
    }

    static recovery_password(email) {
        return http.post('/users/recovery_password', {email})
    }

    static change_password(data) {
        return http.post('/users/change_password', data)
    }
}