import React from 'react'
import Times from '../times'
import Goalstable from './goals.table'

const Goals = () => {
    return (
        <>
            <Times />
            <Goalstable />
        </>
    )
}

export default Goals