import React from 'react'
import { useBasketball } from '../../context/basketball'
import styled from 'styled-components'
const Footer = () => {
    const { selectedSection, setSelectedSection } = useBasketball()
    return (
        <Main>
            <div className="icon" onClick={() => setSelectedSection('settings')}>
                <img src={selectedSection === 'settings' ? "/assets/sports/basketball/footer/settings.active.svg" : "/assets/sports/basketball/footer/settings.svg"} alt="" />
                <span className={selectedSection === 'settings' ? 'selected' : ''}>Ajustes</span>
            </div>
            <div className="icon" onClick={() => setSelectedSection('points')}>
                <img src={selectedSection === 'points' ? "/assets/sports/basketball/footer/points.active.svg" : "/assets/sports/basketball/footer/points.svg"} alt="" />
                <span className={selectedSection === 'points' ? 'selected' : ''} >Puntos</span>
            </div>
            <div className="icon" onClick={() => setSelectedSection('fouls')}>
                <img style={{ position: 'relative', transform: 'translateY(-1px)' }} src={selectedSection === 'fouls' ? "/assets/sports/basketball/footer/fouls.active.svg" : "/assets/sports/basketball/footer/fouls.svg"} alt="" />
                <span className={selectedSection === 'fouls' ? 'selected' : ''}>Fouls</span>
            </div>
        </Main>
    )
}

export default Footer

const Main = styled.div`
min-height: 60px;
height: 60px;
width: 100%;
background-color: #F5F5F5;
border-top: 1px solid #B0B0B0;
display: flex;
justify-content: space-evenly;
align-items: center;
margin: auto;
user-select: none;
.icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    span {
        font-size: 14px;
        color: #B0B0B0;
    }
    span.selected {
        color: #FF8D26;
    }
}
`