import React from 'react'
import styled from 'styled-components'
import { useGlobal } from '../../../../context/global'
import { useSoccer } from '../../../../context/soccer'
import SoccerService from '../../../../services/soccer.service'
const Penaltiestable = () => {
    const { data, setData } = useSoccer()
    const { socket, user } = useGlobal()
    const addGoal = (team) => {
        const teamData = data.teams.filter(object => object.state !== team)
        const oldTeam = data.teams.find(object => object.state === team)
        oldTeam.penalties ? oldTeam.penalties++ : oldTeam.penalties = 1;
        const newData = { ...data, teams: [...teamData, oldTeam] }
        SoccerService.handleMatch({ teams: [...teamData, oldTeam] }, user.token)
        socket.emit("update_results", { teams: [...teamData, oldTeam] })
        setData(newData)
    }
    const substractGoal = (team) => {
        const oldTeam = data.teams.find(object => object.state === team)
        if (!oldTeam.penalties) return
        const teamData = data.teams.filter(object => object.state !== team)
        oldTeam.penalties--;
        const newData = { ...data, teams: [...teamData, oldTeam] }
        SoccerService.handleMatch({ teams: [...teamData, oldTeam] }, user.token)
        socket.emit("update_results", { teams: [...teamData, oldTeam] })
        setData(newData)
    }
    const resetTable = () => {
        let teams = data.teams.map(object => {
            return { ...object, penalties: 0 }
        })
        SoccerService.handleMatch({ teams }, user.token)
        socket.emit("update_results", { teams: teams })
        setData({ ...data, teams })
    }
    return (
        <Main>
            <Counter>
                <h4>{data.teams.find(object => object.state === 'team1')?.penalties || 0}</h4>
                <div className='minus' onClick={() => substractGoal('team1')}>
                    <span>
                        -
                    </span>
                </div>
                <div className='plus' onClick={() => addGoal('team1')}>
                    <span>
                        +
                    </span>
                </div>
            </Counter>
            <Reset>
                <h4>Penales</h4>
                <button onClick={resetTable}>Reset</button>
            </Reset>
            <Counter>
                <h4>{data.teams.find(object => object.state === 'team2')?.penalties || 0}</h4>
                <div className='minus' onClick={() => substractGoal('team2')}>
                    <span>
                        -
                    </span>
                </div>
                <div className='plus' onClick={() => addGoal('team2')}>
                    <span>
                        +
                    </span>
                </div>
            </Counter>
        </Main>
    )
}

export default Penaltiestable

const Main = styled.div`
width: calc(90% - 20px);
padding: 15px 10px;
margin: 20px auto;
margin-bottom: 0;
display: flex;
background-color: #F8F8F8;
border: 1px solid #E5E5E5;
max-width: 500px;
border-radius: 10px;
justify-content: center;
`
const Counter = styled.div`
flex: 1;
h4 {
    text-align: center;
    margin: 0;
    font-size: 64px;
    font-weight: 400;
}
div {
    width: 47px;
    height: 47px;
    border: 1px solid #159800;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    display: inline-block;
    user-select: none;
    span {
        position: absolute;
        left: 50%;
        top: 50%;
        font-weight: 400;
        font-size: 40px;
    }
}
.minus {
        background-color: #FFFFFF;
        span {
            transform: translate(-50%, -50%);
        }
    }
    .plus {
        background-color: #159800;
        color: #FFFFFF;
        float: right;
        span {
            transform: translate(-50% , calc(-50% - 1px));
        }
    }
`
const Reset = styled.div`
text-align: center;
margin-top: 20px;
h4 {
    margin: 0;
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 7px;
}
button {
    margin: 0;
    font-family: "Roboto";
    font-weight: 400;
    padding: 5px 10px;
    border: none;
    border-radius: 23px;
    background-color: #E14343;
    color: #FFFFFF;
}
`