import React, { useContext, useEffect, useState } from "react"
import io from 'socket.io-client'
const ViewerProvider = React.createContext()
export const ViewerContext = ({ children }) => {
    const socket = io(process.env.REACT_APP_WS_URL)
    // const socket = io("ws://localhost:5050")
    const initialUser = JSON.parse(localStorage.getItem('user')) 
    const [user, setUser] = useState(initialUser)
    const values = { socket: socket, user, setUser }
    return <ViewerProvider.Provider value={values}>{children}</ViewerProvider.Provider>
}

export const useViewer = () => {
    return useContext(ViewerProvider)
}