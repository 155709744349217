import React from 'react'
import styled from 'styled-components'
import { useBasketball } from '../../context/basketball'
import Fouls from './body/fouls/fouls'
import Points from './body/points/points'
import Settings from './body/settings/settings'
import Teams from './body/teams'
const Body = () => {
    const { selectedSection } = useBasketball()
    return (
        <Main>
            <Teams />
            {
                selectedSection === 'settings' &&
                <Settings />
            }
            {
                selectedSection === 'points' &&
                <Points />
            }
            {
                selectedSection === 'fouls' &&
                <Fouls />
            }
        </Main>
    )
}

export default Body

const Main = styled.div`
flex: 1;
display: flex;
justify-content: center;
flex-direction: column;
`