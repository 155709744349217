import styled from "styled-components";
import {useSoccer} from '../../../context/soccer'
import { useLocation, useNavigate } from "react-router-dom";

export default function Footer() {
    const { isBoardSettingsOpen, boardTab, setBoardTab} = useSoccer()
    const location = useLocation();
    const navigate = useNavigate();

    const addQueryParameter = (param, value) => {
        const queryParams = new URLSearchParams(location.search);
        queryParams.set(param, value);
        navigate({
          pathname: location.pathname,
          search: queryParams.toString(),
        });
    };

    return (
        <Main>
            {
                isBoardSettingsOpen &&
                <>
                <div 
                onClick={() => {
                    addQueryParameter('settingsBoardTab', 'lower')
                    setBoardTab('lower')
                }}
                className={`${boardTab === 'lower' ? 'active' : ''}`}>
                    <img src={`/assets/common/footer/lower_third${boardTab === 'lower' ? '_active' : ''}.svg`} alt=""/>
                    <span>
                    Lower Third
                    </span>
                </div>
                <div 
                onClick={() => {
                    addQueryParameter('settingsBoardTab', 'full')
                    setBoardTab('full')
                }}
                className={`${boardTab === 'full' ? 'active' : ''}`}>
                    <img src={`/assets/common/footer/fullscreen${boardTab === 'full' ? '_active' : ''}.svg`} alt=""/>
                    <span>
                    Full Screen
                    </span>
                </div>
                </>
            }
        </Main>
    )
}

const Main = styled.div`
min-height: 60px;
height: 60px;
width: calc(100% - 40px);
padding: 0 20px;
background-color: #F5F5F5;
border-top: 1px solid #B0B0B0;
display: flex;
justify-content: space-evenly;
align-items: center;
margin: auto;
> div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    cursor: pointer;
    img {
        height: 20px;
    }
    span {
        font-size: 14px;
        color: #B0B0B0;
    }
    &.active {
        span {
            color: #159800;
        }
    }
}
`
