import styled from "styled-components";
import { useNavigate, Link } from "react-router-dom";
import { useState } from "react";
import UsersService from "../services/users.service";

export default function Recovery() {
    const navigate = useNavigate()
    const [isTokenSent, setIsTokenSent] = useState(false)
    const [email, setEmail] = useState('')
    async function handleSubmitData(e) {
        e.preventDefault()
        try {
            setIsTokenSent(true)
            UsersService.recovery_password(email)

        } catch (error) {
            console.log(error)
            
        }
    }
    return (
        <Main>
            <AppBar>
                <img 
                    src={"/assets/common/left-arrow.svg"}
                    onClick={() => window.location.reload()}
                    alt=""
                />
            </AppBar>
            {
                !isTokenSent 
                ?
            <Form onSubmit={handleSubmitData}>
                <img src="/assets/aiomix.svg" alt=""/>
                <div className="form">
                    <span>¿No puedes iniciar sesión?</span>
                    <div className="inputGroup">
                        <input type="text" required={true}
                        value={email}
                        name="email"
                        onChange={e => setEmail(e.target.value)}
                        />
                        <label>Email</label>
                    </div>
                <button type="submit" className="btn">Reset Password</button>
                <span>No account? <Link to={'/signup'}>Create one</Link></span>
                </div>
            </Form>
            : 
            <MailSent>
                <img src="/assets/aiomix.svg" alt=""/>
                    <span>Se ha enviado un email de
                    recuperacion a su correo</span>
            </MailSent>
            }
        </Main>
    )
}

const Main = styled.div`
background-color: #FFF;
max-height: 100vh;
height: 100vh;
`

const AppBar = styled.div`
height: 10%;
background-color: #000000;
position: relative;
img {
    position: absolute;
    bottom: 20%;
    left: 20px;
    cursor: pointer;
}
`

const Form = styled.form`
width: 100%;
height: 85%;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
gap: 5%;
img {
    margin-bottom: 15%;
}
.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    > span {
        margin-bottom: 7%;
        color: #818181;
    }
}
.inputGroup {
    position: relative;
    width: 270px;
    :first-child {
        margin-bottom: 30px;
    }
}
.inputGroup input {
    font-size: 100%;
    padding: 0.8em 1.3em;
    outline: none;
    border: 2px solid rgb(200, 200, 200);
    background-color: transparent;
    border-radius: 20px;
    width: calc(100% - 2.6em);
}

.inputGroup label {
    font-size: 100%;
    position: absolute;
    left: 0;
    margin-left: calc(1.3em - 10px);
    pointer-events: none;
    transition: all 0.3s ease;
    top: 50%;
    transform: translateY(-50%);
    color: rgb(100, 100, 100);
    background-color: #FFFFFF;
    padding: 0 10px;
}

.btn {
    text-transform: uppercase;
    text-decoration: none;
    padding: 10px 30px;
    border: 1px solid;
    border-radius: 1000px;
    display: inline-block;
    transition: all .2s;
    background-color: #ccc;
    position: relative;
    cursor: pointer;
    margin: 20% auto;
    margin-bottom: 10%;
}

.btn:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(27, 27, 27, .5);
}

.btn::after {
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 100px;
    top: 0;
    left: 0;
    position: absolute;
    z-index: -1;
    transition: all .3s;
    opacity: 0.5;
}

.btn:hover::after {
 background-color: #593A9A;
 transform: scaleX(1.4) scaleY(1.5);
 opacity: 0;
}

.inputGroup :is(input:focus, input:valid)~label {
    top: -10px;
    transform: scale(.9);
}

.inputGroup :is(input:focus, input:valid) {
    border-color: #593A9A;
}

.redirect {
    text-align: center;
    span {
        text-decoration: none;
        font-size: 14px;
        color: #6C6F93;
        font-weight: 400;
        display: block;
        margin-top: 20px;
    }
    
    a {
        text-decoration: none;
        font-size: 14px;
        color: #593A9A;
        font-weight: 400;
    }

}

`
const MailSent = styled.div`
span {
    max-width: 70%;
    text-align: center;
    color: #818181;

}
width: 100%;
height: 85%;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
gap: 10%;

`