import React from 'react'
import styled from 'styled-components'
import { useBasketball } from '../../../../context/basketball'
import BasketballService from '../../../../services/basketball.service'
import { useGlobal } from '../../../../context/global'
const Time = () => {
    const { data, setData, intervalClockId } = useBasketball()
    const { socket, user } = useGlobal()
    const substractTime = () => {
        if (data.time) {
            setData({ ...data, time: data.time - 1 })
            BasketballService.handleMatch({ time: data.time - 1 }, user.token)
            socket.emit("update_results", { time: data.time - 1 })

        } else {
            setData({ ...data, time: 1 })
            BasketballService.handleMatch({ time: 1 }, user.token)
            socket.emit("update_results", { time: 1 })
        }
    }
    const addTime = () => {
        clearInterval(intervalClockId)
        if (data.time) {
            setData({ ...data, time: data.time + 1 })
            BasketballService.handleMatch({ time: data.time + 1 }, user.token)
            socket.emit("update_results", { time: data.time + 1 })
        }
        else {
            setData({ ...data, time: 2 })
            BasketballService.handleMatch({ time: 2 }, user.token)
            socket.emit("update_results", { time: 2 })
        }
    }
    const handleDuration = (amount) => {
        if (!amount) return
        setData({ ...data, clock: amount, duration: amount })
        BasketballService.handleClock({ newTime: amount, duration: amount }, user.token)
        socket.emit("update_results", { clock: amount, duration: amount })
    }
    return (
        <Main>
            <Duration>
                <h4>Duración</h4>
                <div className="buttons">
                    <button className={data.duration === 12 * 60 ? 'active' : ''} onClick={() => handleDuration(12 * 60)}>12:00</button>
                    <button className={data.duration === 10 * 60 ? 'active' : ''} onClick={() => handleDuration(10 * 60)}>10:00</button>
                    <button className={data.duration === 5 * 60 ? 'active' : ''} onClick={() => handleDuration(5 * 60)}>5:00</button>
                </div>
            </Duration>
            <Controller>
                <h4>Tiempo</h4>
                <div className="info">
                    <div className='minus' onClick={substractTime}>
                        -
                    </div>
                    <span>{data.time || 1}</span>
                    <div className='plus' onClick={addTime}>
                        +
                    </div>
                </div>
            </Controller>
        </Main>
    )
}

export default Time

const Duration = styled.div`
text-align: center;
display: flex;
align-items: center;
justify-content: space-between;
flex-direction: column;
h4 {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
}
.buttons {
    white-space: nowrap;
    button {
        margin: 0 3px;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 6px 13px;
        gap: 10px;
        width: 50px;
        height: 30px;
        background: #FFFFFF;
        font-family: "Roboto";
        border: 1px solid #FF8D26;
        border-radius: 21px;
    }
    button.active {
        background-color: #FF8D26;
        color: #FFFFFF;
        font-weight: 700;
    }
}
`

const Controller = styled.div`
text-align: center;
display: flex;
align-items: center;
justify-content: space-between;
flex-direction: column;
h4 {
    margin: 0;
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: 400;
}
.info {
    display: flex;
    justify-content: center;
    align-items: center;
    span {
        font-size: 30px;
        margin: 0 15px;
    }
    div {
        border: 1px solid #FF8D26;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        border-radius: 50%;
        user-select: none;
    }
    div.plus {
        background-color: #FF8D26;
        color: #ffffff;
    }
    div.minus {
        background-color: #ffffff;
    }
}
`

const Main = styled.div`
background-color: #F8F8F8;
border: 1px solid #E5E5E5;
border-radius: 10px;
display: flex;
justify-content: center;
align-items: center;
padding: 20px;
max-width: 500px;
width: calc(90% - 40px);
margin: auto;
gap: 15px;
> * {
    flex: 1;
    height: 60px;
}
`