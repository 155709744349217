import React from 'react'
import styled from 'styled-components'
const Main = styled.div`
height: 60px;
width: 100%;
background-color: red;
border-top: 1px solid #B0B0B0;
`
const Footer = () => {
    return (
        <Main>

        </Main>
    )
}

export default Footer