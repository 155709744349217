import styled from "styled-components";
import SoccerService from '../../../services/soccer.service'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { useGlobal } from '../../../context/global'
import { useSoccer } from "../../../context/soccer";

export default function Default() {
    const { sport } = useParams()
    const navigate = useNavigate()
    const { socket, user, setUser} = useGlobal()
    const { setIsSidebarOpen, setData, initialState, setIsBoardSettingsOpen } = useSoccer()
    const location = useLocation();

    const addQueryParameter = (param, value) => {
        const queryParams = new URLSearchParams(location.search);
        queryParams.set(param, value);
        navigate({
          pathname: location.pathname,
          search: queryParams.toString(),
        });
    };

    return (
        <Main>
            <div className="item" onClick={() => navigate('/')}>
                <img src="/assets/common/sidebar/home.svg" alt="" />
                <span>Inicio</span>
            </div>
            <div className='item'
            onClick={() => {
                setIsBoardSettingsOpen(true)
                addQueryParameter('isBoardSettingsModalOpen', 'true')
            }}
            >
                <img src='/assets/common/sidebar/settings.svg' alt=''/>
                <span>Ajuste Tablero</span>
            </div>
            <div className="item">
                <img src="/assets/common/sidebar/table.svg" alt="" />
                <span>Mostrar Tablero</span>
                <a href={`/share/${sport}/${user.user?._id}`} target='_BLANK' ></a>
            </div>
            <div className="item" onClick={() => {
                setData(initialState);
                setIsSidebarOpen(false)
                SoccerService.handleMatch({ ...initialState, clock: 0, clock_status: 'reboot', extra_minutes: 0, time: 0 }, user.token)
                socket.emit("update_results", { ...initialState, clock: 0, clock_status: 'reboot', extra_minutes: 0, time: 0 }, user.token)
            }}>
                <img src="/assets/common/sidebar/restart.svg" alt="" />
                <span>Reset Tablero</span>
            </div>
            <div className="item" onClick={() => {
                navigate('/')
                SoccerService.finishTable(user.token)
                socket.emit("update_results", { ...initialState, clock: 0, clock_status: 'reboot', extra_minutes: 0, time: 0 })
            }}>
                <img src="/assets/common/sidebar/close.svg" alt="" />
                <span>Finalizar Tablero</span>
            </div>
            <div className='item'
            onClick={() => {
                setUser(null)
                localStorage.removeItem('user')
                navigate('/')
            }}
            >
                <img src={`/assets/common/sidebar/logout.svg`} alt=''></img>
                Cerrar sesion
            </div>
        </Main>
    )
}

const Main = styled.div`
flex: 1;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
gap: 25px;
.item {
    background-color: #159800;
    width: 190px;
    border-radius: 44px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    cursor: pointer;
    gap: 7px;
    position: relative;
    a {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
    }
}
`