import React from 'react'
import styled from 'styled-components'
import Clock from './clock'
import Time from './time'

const Settings = () => {
    return (
        <Main>
            <Time />
            <Clock />
        </Main>
    )
}

export default Settings

const Main = styled.div``
