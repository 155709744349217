import React from 'react'
import styled from 'styled-components'
import { useSoccer } from '../../context/soccer'
import { useLocation, useNavigate } from 'react-router-dom'
const Footer = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const addQueryParameter = (param, value) => {
        const queryParams = new URLSearchParams(location.search);
        queryParams.set(param, value);
        navigate({
          pathname: location.pathname,
          search: queryParams.toString(),
        });
    };
    const { selectedSection, setSelectedSection } = useSoccer()
    return (
        <Main>
            <div className="icon" onClick={() => {
                setSelectedSection('settings')
                addQueryParameter('tab', 'settings')
                }}>
                <img src={selectedSection === 'settings' ? "/assets/sports/football/footer/setting.active.svg" : "/assets/sports/football/footer/setting.svg"} alt="" />
                <span className={selectedSection === 'settings' ? 'selected' : ''}>Ajustes</span>
            </div>
            <div className="icon" onClick={() => {
                setSelectedSection('goals')
                addQueryParameter('tab', 'goals')
                }}>
                <img src={selectedSection === 'goals' ? "/assets/sports/football/footer/goals.active.svg" : "/assets/sports/football/footer/goals.svg"} alt="" />
                <span className={selectedSection === 'goals' ? 'selected' : ''} style={{ position: 'relative', transform: 'translateY(3px)' }}>Goles</span>
            </div>
            <div className="icon" onClick={() => {
                setSelectedSection('penalties')
                addQueryParameter('tab', 'penalties')
                }
                }>
                <img src={selectedSection === 'penalties' ? "/assets/sports/football/footer/penalties.active.svg" : "/assets/sports/football/footer/penalties.svg"} alt="" style={{ marginTop: '3px' }} />
                <span className={selectedSection === 'penalties' ? 'selected' : ''}>Penales</span>
            </div>
        </Main>
    )
}

export default Footer

const Main = styled.div`
min-height: 60px;
height: 60px;
width: 100%;
background-color: #F5F5F5;
border-top: 1px solid #B0B0B0;
display: flex;
justify-content: space-evenly;
align-items: center;
margin: auto;
user-select: none;
.icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    span {
        font-size: 14px;
        color: #B0B0B0;
    }
    span.selected {
        color: #159800;
    }
}
`