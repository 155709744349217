import { useState } from "react";
import styled from "styled-components";
import UsersService from "../services/users.service";
import { useNavigate, useParams } from "react-router-dom";

export default function RecoveryCheck() {
    const {token} = useParams()
    const [isPasswordChanged, setIsPasswordChanged] = useState(false)
    const [newPassword, setNewPassword] = useState('')
    const navigate = useNavigate()
    const handleSubmitData = (e) => {
        e.preventDefault()
        try {
            setIsPasswordChanged(true)
            UsersService.change_password({token_id: token, password: newPassword})
            setIsPasswordChanged(true)

        } catch (error) {
            console.log(error)
            
        }
    }
    return (
        <Main>
            <AppBar/>
            {
                !isPasswordChanged ?
            <Form onSubmit={handleSubmitData}>
                <img src="/assets/aiomix.svg" alt=""/>
                <div className="form">
                    <div className="inputGroup">
                        <input type="text" required={true}
                        value={newPassword}
                        name="email"
                        onChange={e => setNewPassword(e.target.value)}
                        />
                        <label>New password</label>
                    </div>
                    <button type="submit" className="btn">Reset Password</button>
                </div>
            </Form>
            :
            <PasswordChanged>
                <img src="/assets/aiomix.svg" alt=""/>
                <div>
                    <span>Su contraseña ha sido correctamente restablecida</span>
                    <button className="btn"
                    onClick={() => navigate('/')}
                    >Go login</button>
                </div>
            </PasswordChanged>
            }
        </Main>
    )
}

const Main = styled.section`
background-color: #FFF;
max-height: 100vh;
height: 100vh;
`

const AppBar = styled.div`
height: 10%;
background-color: #000000;
`

const Form = styled.form`
width: 100%;
height: 85%;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
gap: 5%;
img {
    margin-bottom: 10%;
}
.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20%;
}
.inputGroup {
    position: relative;
    width: 270px;
    :first-child {
        margin-bottom: 30px;
    }
}
.inputGroup input {
    font-size: 100%;
    padding: 0.8em 1.3em;
    outline: none;
    border: 2px solid rgb(200, 200, 200);
    background-color: transparent;
    border-radius: 20px;
    width: calc(100% - 2.6em);
}

.inputGroup label {
    font-size: 100%;
    position: absolute;
    left: 0;
    margin-left: calc(1.3em - 10px);
    pointer-events: none;
    transition: all 0.3s ease;
    top: 50%;
    transform: translateY(-50%);
    color: rgb(100, 100, 100);
    background-color: #FFFFFF;
    padding: 0 10px;
}

.btn {
    text-transform: uppercase;
    text-decoration: none;
    padding: 10px 30px;
    border: 1px solid;
    border-radius: 1000px;
    background-color: #ccc;
    display: inline-block;
    transition: all .2s;
    position: relative;
    cursor: pointer;
}

.btn:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(27, 27, 27, .5);
}

.btn::after {
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 100px;
    top: 0;
    left: 0;
    position: absolute;
    z-index: -1;
    transition: all .3s;
    opacity: 0.5;
}

.btn:hover::after {
 background-color: #593A9A;
 transform: scaleX(1.4) scaleY(1.5);
 opacity: 0;
}

.inputGroup :is(input:focus, input:valid)~label {
    top: -10px;
    transform: scale(.9);
}

.inputGroup :is(input:focus, input:valid) {
    border-color: #593A9A;
}

.redirect {
    text-align: center;
    span {
        text-decoration: none;
        font-size: 14px;
        color: #6C6F93;
        font-weight: 400;
        display: block;
        margin-top: 20px;
    }
    
    a {
        text-decoration: none;
        font-size: 14px;
        color: #593A9A;
        font-weight: 400;
    }

}
`

const PasswordChanged = styled.div`
span {
    max-width: 70%;
    text-align: center;
    color: #818181;
}
width: 100%;
height: 85%;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
gap: 10%;
> div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
}
.btn {
    text-transform: uppercase;
    text-decoration: none;
    padding: 10px 30px;
    border: 1px solid;
    border-radius: 1000px;
    background-color: #ccc;
    display: inline-block;
    transition: all .2s;
    position: relative;
    cursor: pointer;
    width: fit-content;
}

.btn:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(27, 27, 27, .5);
}

.btn::after {
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 100px;
    top: 0;
    left: 0;
    position: absolute;
    z-index: -1;
    transition: all .3s;
    opacity: 0.5;
}

.btn:hover::after {
 background-color: #593A9A;
 transform: scaleX(1.4) scaleY(1.5);
 opacity: 0;
}
`