import React, {useState, useRef, useEffect} from 'react'
import styled, { css } from "styled-components"
import secondsToMinutes from '../../utils/toMinutes'
const Basketball = ({ data }) => {
    const time = (data.clock - data.clock_seconds_diff) > 0 ? data.clock - data.clock_seconds_diff : 0
    const [isEditing, setIsEditing] = useState(false)
    const [containerStyle, setContainerStyle] = useState({
        transform: "translate(-50%, -50%) scale(1)",

    });

    function useOutsideAlerter(ref) {
        useEffect(() => {
          /**
           * Alert if clicked on outside of element
           */
          function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
              setIsEditing(false)
            }
          }
          // Bind the event listener
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
          };
        }, [ref]);
      }
      const containerRef = useRef();
      useOutsideAlerter(containerRef)

      const handleResize = (event, direction) => {
        event.preventDefault();
        event.stopPropagation()
        if (!isEditing) return
        const container = containerRef.current
        if (container) {
          const containerRect = container.getBoundingClientRect();
          const containerWidth = containerRect.width;
          const containerHeight = containerRect.height;
        
          const handleDrag = (event) => {
            const clientX = event.type === "mousemove" ? event.clientX : event.touches[0].clientX;
            const clientY = event.type === "mousemove" ? event.clientY : event.touches[0].clientY;
            
            const screenWidth = window.innerWidth - 70;
            
            const newWidth = direction.includes("right")
            ? clientX - containerRect.left
            : containerWidth + containerRect.left - clientX;
          const newHeight = direction.includes("bottom")
            ? clientY - containerRect.top
            : containerHeight + containerRect.top - clientY;
              const minimumScale = 0.5;
              const aspectRatio = 651 / 155;
                const translateEndIndex = containerStyle.transform.indexOf(' scale')
                const translate = containerStyle.transform.slice(0, translateEndIndex)
                
                if (newWidth / newHeight > aspectRatio) {
                  const calculatedScale = newWidth / 651;
                  const maxScale = screenWidth / 651;
                  let newScale = Math.min(calculatedScale, maxScale);
                  if (newScale < minimumScale) {
                    newScale = minimumScale;
                  }
                  setContainerStyle({
                    ...containerStyle,
                    transform: `${translate} scale(${newScale})`,
                  });
                } else {
                  const calculatedScale = newHeight / 155;
                  
                  // Prevent scaling beyond screen width
                  const maxScale = screenWidth / 651;
                  let newScale = Math.min(calculatedScale, maxScale);
                  if (newScale < minimumScale) {
                    newScale = minimumScale;
                  }
                  setContainerStyle({
                    ...containerStyle,
                    transform: `${translate} scale(${newScale})`,
                  });
                
                }
          }
          const handleDragEnd = () => {
            document.removeEventListener("mousemove", handleDrag);
            document.removeEventListener("touchmove", handleDrag)
            document.removeEventListener("mouseup", handleDragEnd);
            document.removeEventListener("touchend", handleDragEnd)
          };
          document.addEventListener("mousemove", handleDrag);
          document.addEventListener("mouseup", handleDragEnd);
          document.addEventListener("touchmove", handleDrag)
          document.addEventListener("touchend", handleDragEnd)
        }
      };
      const handleDragStart = (event) => {
        event.preventDefault();
        if (!isEditing) return
        const container = containerRef.current
        if (container) {
          const containerRect = container.getBoundingClientRect();
          const offsetX = event.type === "mousedown" ?  event.clientX - containerRect.left: event.touches[0].clientX - containerRect.left;
          const offsetY = event.type === "mousedown" ? event.clientY - containerRect.top :event.touches[0].clientY - containerRect.top ;
          const handleDrag = (event) => {
            event.preventDefault()
            const clientX = event.type === "mousemove" ? event.clientX : event.touches[0].clientX;
            const clientY = event.type === "mousemove" ? event.clientY : event.touches[0].clientY;
            const scale = Number(containerStyle.transform.split(" ")[2].replace("scale(", "").replace(")", ""))
            const newLeft = clientX - (offsetX / scale);
            const newTop = clientY - (offsetY / scale);
            setContainerStyle({
              ...containerStyle,
              top: 0,
              left: 0,
              transform: `translate(${newLeft}px, ${newTop}px) ${containerStyle.transform.split(" ")[2]}`,
            });
          };
    
          const handleDragEnd = () => {
            document.removeEventListener("mousemove", handleDrag);
            document.removeEventListener("touchmove", handleDrag)
            document.removeEventListener("mouseup", handleDragEnd);
            document.removeEventListener("touchend", handleDragEnd)
          };
    
          document.addEventListener("mousemove", handleDrag);
          document.addEventListener("touchmove", handleDrag, {passive: false})
          document.addEventListener("touchend", handleDragEnd)
          document.addEventListener("mouseup", handleDragEnd);
        }
      };
    return (
        <Main background_disabled={!data.is_showing_results_background}>
        <img className='background' src={'/assets/results/background.png'}/>
        <Container
        ref={containerRef}
        style={containerStyle}
        onMouseDown={handleDragStart}
        onTouchStart={handleDragStart}
        className={isEditing ? 'editing' : ''}
        onDoubleClick={() => setIsEditing(!isEditing)}
        >
            {
                isEditing &&
                <>
                <TopLeftHandle onTouchStart={(e) => handleResize(e, "top-left")} onMouseDown={(e) => handleResize(e, "top-left")} />
                <TopRightHandle onTouchStart={(e) => handleResize(e, "top-right")} onMouseDown={(e) => handleResize(e, "top-right")} />
                <BottomLeftHandle onTouchStart={(e) => handleResize(e, "bottom-left")} onMouseDown={(e) => handleResize(e, "bottom-left")} />
                <BottomRightHandle onTouchStart={(e) => handleResize(e, "bottom-right")} onMouseDown={(e) => handleResize(e, "bottom-right")} />
                </>
            }
            <div className='parallelogram_left'>
                <span>
                    {data.teams ? (data.teams.find(obj => obj.state === 'team1').name || 'TEA') : 'TEA'}
                </span>
                <img  src='/assets/results/parallelogram_left.svg' alt=''/>
            </div>
            <div className='parallelogram_right'>
            <span>
                    {data.teams ? (data.teams.find(obj => obj.state === 'team2').name || 'TEA') : 'TEA'}
                </span>
                <img  src='/assets/results/parallelogram_right.svg' alt=''/>
            </div>
            <div className='results'>
                <div>
                  <span>
                    {data.teams ? (data.teams.find(obj => obj.state === 'team1').points || 0) : 0}
                  </span>
                </div>
                <img src='/assets/results/results.svg' alt=''/>
                <div>
                  <span>
                    {data.teams ? (data.teams.find(obj => obj.state === 'team2').points || 0) : 0}
                  </span>
                </div>
            </div>
            <div className='time'>
                <span>
                    {data.time || 1}T
                </span>
                <span>
                    {data.clock_status === 'running' ? secondsToMinutes(time) : secondsToMinutes(data.clock)}
                </span>
                <img src='/assets/results/time.svg' alt=''/>
            </div>
            <img className='separator' src='/assets/results/separator.svg' alt=''/>
        </Container>
        </Main>
    )
}

export default Basketball

const Main = styled.div`
width: calc(100vw - 80px);
height: calc(100vh - 80px);
overflow: hidden;
position: relative;
padding: 40px;
> img {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
}
${props => props.background_disabled === true && css`
  img {
    opacity: 0;
  }
`}
` 

const Container = styled.div`
position: absolute;
top: 50%;
left: 50%;
height: 155px;  
max-width: calc(100% - 30px);
transform: translate(-50%, -50%) scale(1);
width: 651px;
font-family: 'Poppins';
z-index: 10;
cursor: pointer;
&.editing {
    cursor: move;
    ::after {
        content: "";
        position: absolute;
        left: 0px;
        top: 0px;
        border: 1px solid #30BCED;
        width: 100%;
        height: 100%;
    }
}
> * {
    position: absolute;
}
.background {
    width: 100vh;
    position: absolute;
    height: 100vw;
    object-fit: cover;
}
.parallelogram_left {
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    height: 90px;
    width: 202px;
    span {
        color: #FFFFFF;
        z-index: 20;
        text-transform: uppercase;
        font-size: 40px;
    }
    img {
        position: absolute;
        height: 100%;
    }
}
.parallelogram_right {
    left: 452px;
    height: 90px;
    width: 202px;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
        color: #FFFFFF;
        z-index: 20;
        text-transform: uppercase;
        font-size: 40px;
    }
    img {
        position: absolute;
        height: 100%;
    }
}
.results {
    height: 90px;
    left: 162.5px;
    padding:  0 40px;
    width: calc(327px - 80px);
    display: flex;
    > div {
        z-index: 10;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 60px;
        font-family: 'Poppins';
    }
    img {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}
.time {
    top: 90px;
    height: 64.5px;
    width: 251px;
    left: 201px;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
        color: #000000;
        z-index: 20;
        text-transform: uppercase;
        font-size: 40px;
        :first-child {
            margin-right: 15px;
        }
    }
    img {
        height: 100%;
        position: absolute;
        top: 0;
    }
}
.separator {
    height: 90px;
    left: 330px;
}
`

const ResizeHandle = styled.div`
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: white;
  border: 1px solid #30BCED;
  border-radius: 50%;
  z-index: 100;
`;

const TopLeftHandle = styled(ResizeHandle)`
  top: -7px;
  left: -7px;
  cursor: nwse-resize;
`;

const TopRightHandle = styled(ResizeHandle)`
  top: -7px;
  right: -7px;
  cursor: nesw-resize;
`;

const BottomLeftHandle = styled(ResizeHandle)`
  bottom: -7px;
  left: -7px;
  cursor: nesw-resize;
`;

const BottomRightHandle = styled(ResizeHandle)`
  bottom: -7px;
  right: -7px;
  cursor: nwse-resize;
`;