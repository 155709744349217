import React from 'react'
import styled from 'styled-components'
import { useRugby } from '../../context/rugby'
import { useNavigate, useParams } from 'react-router-dom'
import RugbyService from '../../services/rugby.service'
import { useGlobal } from '../../context/global'
const Sidebar = () => {
    const navigate = useNavigate()
    const { isSidebarOpen, setIsSidebarOpen, data, setData, initialState } = useRugby()
    const { socket, user, setUser } = useGlobal()
    const { sport } = useParams()
    isSidebarOpen ?
        document.querySelector('html').style.overflowY = 'hidden'
        :
        document.querySelector('html').style.overflowY = 'overlay'
    return (
        <Main open={isSidebarOpen}>
            <Banner>
                <img src="/assets/sports/rugby/banner.svg" alt="" />
                <div className="info">
                    <div className="arrow" onClick={() => setIsSidebarOpen(false)}>
                        <img src="/assets/common/sidebar/back.svg" alt="" />
                    </div>
                    <h3>Rugby</h3>
                    <span />
                </div>
            </Banner>
            <Body>
                <div className="item" onClick={() => navigate('/')}>
                    <img src="/assets/common/sidebar/home.svg" alt="" />
                    <span>Inicio</span>
                </div>
                <div className='item'>
                    <img src='/assets/common/sidebar/settings.svg' alt=''/>
                    <span>Ajuste Tablero</span>
                </div>
                <div className="item">
                    <img src="/assets/common/sidebar/table.svg" alt="" />
                    <span>Mostrar Tablero</span>
                    <a href={`/share/${sport}/${user.user._id}`} target='_BLANK'></a>
                </div>
                <div className="item" onClick={() => {
                    setData(initialState);
                    setIsSidebarOpen(false)
                    RugbyService.handleMatch({ ...initialState, clock: 0, clock_status: 'reboot', time: 0 }, user.token)
                    socket.emit("update_results", { ...initialState, clock: 0, clock_status: 'reboot', time: 0 })
                }}>
                    <img src="/assets/common/sidebar/restart.svg" alt="" />
                    <span>Reset Tablero</span>
                </div>
                <div className="item" onClick={() => {
                    navigate('/')
                    RugbyService.finishTable(user.token)
                    socket.emit("update_results", { ...initialState, clock: 0, clock_status: 'reboot', time: 0 })
                }}>
                    <img src="/assets/common/sidebar/close.svg" alt="" />
                    <span>Finalizar Tablero</span>
                </div>
                <div className={`item ${!data.is_showing_results_background && 'disabled'}`}
                onClick={() => {
                    RugbyService.handleMatch({is_showing_results_background: !data.is_showing_results_background}, user.token)
                    socket.emit("update_results", {is_showing_results_background: !data.is_showing_results_background})
                    const new_data = {...data, is_showing_results_background: !data.is_showing_results_background}
                    setData(new_data)
                    console.log(new_data)
                }}
                >
                        <img src={`/assets/common/sidebar/${data.is_showing_results_background ? 'eyeball' : 'eyeball-closed'}.svg`} alt="" />                        
                    <span>Tanteador Fondo</span>
                </div>
                <div className='item'
                onClick={() => {
                    setUser(null)
                    localStorage.removeItem('user')
                    navigate('/')
                }}
                >
                    <img src={`/assets/common/sidebar/logout.svg`} alt=''></img>
                    Cerrar sesion
                </div>
            </Body>
            <Footer />
        </Main>
    )
}

export default Sidebar

const Main = styled.div`
height: 100vh;
width: 100%;
background-color: #FFFFFF;
position: fixed;
top: 0;
left: -100%;
transition: left 0.3s ease;
display: flex;
flex-direction: column;
${props => props.open === true && { left: 0 }}
`
const Banner = styled.div`
background-color: #159800;
width: 100%;
height: 150px;
position: relative;
> img {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    height: 100%;
    width: 90%;
    max-width: 300px;
}
.info {
    width: 95%;
    max-width: 500px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    > * {
        flex: 1;
        margin: 0;
    }
    h3 {
        text-align: center;
        font-size: 17px;
        font-weight: bold;
        color: #ffffff;
        text-transform: uppercase;
        height: fit-content;
    }
    .arrow img {
        cursor: pointer;
    }
   
}
`
const Footer = styled.div`
height: 60px;
width: 100%;
background-color: #F5F5F5;
border-top: 1px solid #B0B0B0;
display: flex;
justify-content: center;
align-items: center;
margin: auto;
`
const Body = styled.div`
height: calc(70vh - 25px);
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
gap: 25px;
flex: 1;
.item {
    background-color: #159800;
    width: 190px;
    border-radius: 44px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    cursor: pointer;
    gap: 7px;
    position: relative;
    a {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
    }
}
.item.disabled {
    background-color: #969696;
}
`